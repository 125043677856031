import productsConstants from '@core/utils/constants/products';
import feeConstants from '@core/utils/constants/fee';
import ticketConstants from '@core/utils/constants/ticket';
import normalizeCharge from '@core/data/response/normalizeCharge';
import { normalizeCartItem } from '@core/data/response/normalizeCartItem';
import normalizePackageOffer from '@core/data/response/normalize/packageOffer';
import utils from '@core/data/response/_utils';
import {
    parseDateString as dateUtilsParseDateString
} from '@tenant/app/utils/dateUtils';
import { normalizeGiftCard } from './normalizeGiftCard';

export function normalizeCart( cart ) {
    const collections = cart.items.map( normalizeCartItem );

    // temporary solution for showing resale tickets tax
    if ( collections[0].productTypeId === productsConstants.TYPES.TICKET 
        && collections[0].items[0].selection === ticketConstants.TYPES.RESALE ) {
        collections[0].offers[0].charges.forEach( offer => {
            if ( offer.type === feeConstants.TYPES.BASE && offer.tax === 0 ) {
                offer.tax = cart.totals.tax / collections[0].items[0].quantity;
            }
        } );
    }

    const itemExpireAt = dateUtilsParseDateString( collections[ 0 ].expireAt );
    const loyaltyCurrency = collections[ 0 ].loyaltyCurrency;

    return {
        cart: {
            id: cart.uuid,
            userId: cart.userId || null,
            tenantId: cart.tenantId || null,
            created: cart.created ? dateUtilsParseDateString( cart.created ) : new Date( ),
            expireAt: cart.expireAt ? dateUtilsParseDateString( cart.expireAt ) : itemExpireAt,
            giftCard: cart.giftCard ? normalizeGiftCard( cart.giftCard ) : null,
            hintCode: cart.hint?.code || null,
        },
        collections,
        packageOffer: cart.offer ? normalizePackageOffer( cart.offer ) : { },
        order: {
            redeemPoints: cart.totals.redeemPoints || 0,
            earnPoints: cart.totals.earnPoints || 0,
            totalPrice: cart.totals.totalPrice || 0,
            totalDelivery: utils.getShippingPrice( collections ) || 0,
            basePrice: cart.totals.basePrice || 0,
            fee: cart.totals.fee || 0,
            currency: cart.totals.currency || null,
            charges: collections.reduce( ( acc, item ) => {
                return acc = acc.concat( item.charges.map( normalizeCharge ) );
            }, [] ),
            price: {
                basePrice: cart.totals.basePrice,
                fee:cart.totals.fee
            },
            loyaltyCurrency: loyaltyCurrency || null
        }
    };
}
