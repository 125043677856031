import Vue from 'vue';
import { getQueryParamsCurrent as getQueryParamsCurrentWindowLocation } from '@core/utils/windowLocationUtils';
import '@tenant/app/common';
import App from '@tenant/app/App.vue';
import { SUPPORTED_LOCALES } from '@tenant/app/utils/constants/i18n';
import i18nFactory from './i18nFactory';
import routerFactory from './routerFactory';
import storeFactory from './storeFactory';
import vuetifyFactory from './vuetifyFactory';

export default {
    create: async ( config = { } ) => {
        const i18n          = await i18nFactory.create( config.i18n || { } );
        const router        = routerFactory.create( { routes: config.routes || [ ] } );
        const vuetify       = vuetifyFactory.create( config.theme );
        const store         = storeFactory.create( config.store );

        const getInitialLanguage = ( ) => {
            const queryLang = getQueryParamsCurrentWindowLocation( ).lang;
            const storeLang = store.state.appState.locale;

            if ( queryLang && SUPPORTED_LOCALES.indexOf( queryLang ) !== -1 ) {
                return queryLang;
            }

            if ( storeLang && SUPPORTED_LOCALES.indexOf( storeLang ) !== -1 ) {
                return storeLang;
            }

            return SUPPORTED_LOCALES[ 0 ] || 'en-US';
        };

        store.dispatch( 'appState/setLocale', getInitialLanguage( ) );

        router.onError( error => {
            if ( /loading chunk [^\n]+ failed./i.test( error.message ) ) {
                window.location.reload( );
            }
        } );

        window.$store = store;

        const app = new Vue( {
            router,
            store,
            vuetify,
            i18n,
            render: h => h( App )
        } );
        app.$mount( '#app' );
    }
};
