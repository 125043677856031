<template>
    <!-- eslint-disable vue/no-v-html -->
    <svg v-if="!isLoading && isValidSymbolId"
        class="gst-base-icon"
        v-bind="{ ...$attrs, ...svgAttrs }"
        :viewBox="svgAttrs && svgAttrs.viewbox"
        xmlns="http://www.w3.org/2000/svg"
        v-on="$listeners"
        v-html="svgContent">
    </svg>
    <!-- eslint-enable vue/no-v-html -->
</template>
<script>
    import LogService from '@core/services/LogService';

    export default {
        name: 'BaseIcon',
        props: {
            symbolId: {
                type: String,
                required: true
            },
            width: {
                type: [ String, Number ],
                default: null
            },
            height: {
                type: [ String, Number ],
                default: null
            }
        },
        data() {
            return {
                svgContent: null,
                svgAttrs: null,
                isLoading: false,
                isValidSymbolId: false
            };
        },
        methods: {
            async loadSvgSprite( ) {
                try {
                    const response = await import( '!raw-loader!@tenant/public/assets/sprite-icons.svg' );
                    return response.default;
                } catch ( error ) {
                    LogService.error( 'Error loading svg sprite.' );
                    return false;
                }
            },
            async loadFallbackSvgSprite( ) {
                try {
                    const response = await import( '!raw-loader!@tenants/_main/public/assets/sprite-icons.svg' );
                    return response.default;
                } catch ( error ) {
                    LogService.error( 'Error loading fallback svg sprite.' );
                    return false;
                }
            },
            async loadSvgIcon() {
                this.isLoading = true;
                let response = await this.loadSvgSprite( );

                if ( !response ) {
                    response = await this.loadFallbackSvgSprite( );
                }

                if ( response ) {
                    const regex = '<svg[^>]*id[\\s]?=[\\s]?[\'"]' + this.symbolId + '[\'"][\\s\\S]*?</svg>';
                    const regexSymbolResponse = new RegExp( regex ).exec( response );
                    if ( regexSymbolResponse ) {
                        const svgSymbol = regexSymbolResponse[0];

                        if ( svgSymbol ) {
                            this.isValidSymbolId = true;
                            const parser = new DOMParser();
                            const doc = parser.parseFromString( svgSymbol, 'text/html' );
                            const element = doc.querySelector( 'svg' );

                            this.svgContent = element.innerHTML;
                            this.svgAttrs = {
                                fill: element.getAttribute( 'fill' ) || null,
                                viewbox: element.getAttribute( 'viewBox' ) || null,
                                width: this.width ? this.width : element.getAttribute( 'width' ) || null,
                                height: this.height ? this.height : element.getAttribute( 'height' ) || null,
                                ['data-icon-id']: this.symbolId
                            };
                        }
                    } else {
                        this.isValidSymbolId = false;
                        LogService.warn( `Icon symbol id: "${this.symbolId}" does not exists.` );
                    }
                }
                this.isLoading = false;
            }
        },
        async mounted() {
            await this.loadSvgIcon( );
        }
    };
</script>
