export default {
    DEFAULT_CONFIG: {
        LONGITUDE: -73.9851,
        LATITUDE: 40.7594,
        ZOOM: 10,
        DISTANCE_TRIGGER_NEW_SEARCH_METERS: 1000, // 1 KM
        ZOOM_HOTEL_DETAILS: 15,
        PRISMIC: {
            CUSTOM_TYPE : 'map-default-center',
            MAP_TYPES: {
                UNKNOWN_EVENT: 'unknown-event-flow'
            }
        }
    }
};
