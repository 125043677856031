import environmentConstants from '@core/utils/constants/environment';
import hotjarConstants from '@tenants/ticketmaster/app/utils/constants/hotjar';
import {
    getType as environmentUtilsGetType
} from '@tenant/app/utils/environmentUtils';

export function getSiteId( environmentType ) {
    switch ( environmentType ) {
    case environmentConstants.TYPES.TEST:
        return hotjarConstants.SITE_ID.TEST;
    case environmentConstants.TYPES.UAT:
        return hotjarConstants.SITE_ID.UAT;
    case environmentConstants.TYPES.PRODUCTION:
        return hotjarConstants.SITE_ID.PRODUCTION;
    default:
        return hotjarConstants.SITE_ID.LOCALHOST;
    }
}

export function getConfig() {
    const environmentType = environmentUtilsGetType();
    const isProduction = environmentType !== environmentConstants.TYPES.LOCALHOST;
    const siteId = getSiteId( environmentType );

    const config = {
        id: siteId,
        isProduction: isProduction
    };

    return config;
}