import isEmpty from 'lodash/isEmpty';
import {
    parseDateString as dateUtilsParseDateString
} from '@core/utils/dateUtils';


export default function normalize ( data ) {
    let ret = { };

    ret.id                      = data.rateId;
    ret.currency                = data.currency;
    ret.inventory               = data.inventory;
    ret.netPrice                = data.netPrice ? data.netPrice : ret.totalPrice ; // netPrice is base total price
    ret.totalPrice              = data.totalPrice ? data.totalPrice : undefined;
    ret.mealPlan                = data.mealPlan;
    ret.fees                    = data.fees.map( item => normalizeFee( item ) );
    ret.type                    = data.rateType || null;
    ret.paymentType             = data.paymentType || null;
    ret.cancellationPolicies    = data.cancellationPolicies ? data.cancellationPolicies.map( item => normalizeCancellationPolicy( item ) ) : null;
    ret.totals                  = {
        basePrice: ret.totalPrice || 0,
        totalPrice: ret.totalPrice || 0
    };
    ret.packageOffer            = !isEmpty( data.offer ) ? normalizePackageOffer( data.offer ) : null;

    return ret;
}


function normalizeFee( data = { } ) {
    return {
        dateRange: {
            startDate: data.dateRange.startDate,
            endDate: data.dateRange.endDate
        },
        fee: {
            name: data.fee.name,
            type: data.fee.type,
            typeIncluded: data.fee.included,
            amount: data.fee.amount,
            amountType: data.fee.amountType,
            chargeType: data.fee.chargeType,
            effectivePerson: data.fee.effectivePerson
        }
    };
}


function  normalizePackageOffer ( data ) {
    return  {
        basePrice      : data.basePrice,
        id             : data.id,
        ticketTypeId   : data.ticketTypeId,
        ticketType     : data.type,
        inventory: data.inventory,
        passcodeProtected : data.passcodeProtected
    };
}


function normalizeCancellationPolicy ( data ) {
    return {
        amount : data.amount,
        fromAt : data.from ? dateUtilsParseDateString( data.from ) : null,
        percent : data.percent,
        numberOfNights : data.numberOfNights,
        _originalFromAt : data.from
    };
}
