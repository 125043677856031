import { normalizeOrderItem } from '@core/data/response/normalizeOrderItem';
import normalizeCharge from '@core/data/response/normalizeCharge';
import normalizePackageOffer from '@core/data/response/normalize/packageOffer';
import {
    parseDateString as dateUtilsParseDateString
} from '@tenant/app/utils/dateUtils';
import { normalizeGiftCard } from './normalizeGiftCard';

/**
 * Order
 *
 * Normalize Order Object
 */

export function normalizeOrder( data ) {
    return {
        created:        dateUtilsParseDateString( data.created ),
        createdAt:      dateUtilsParseDateString( data.createdAt ),
        currency:       data.currency,
        id:             data.id,
        uuid:           data.uuid,
        items:          data.items.map( normalizeOrderItem ),
        paymentCash:    data.paymentCash,
        paymentPoints:  data.pointsPayment,
        paymentStatus:  data.paymentStatus,
        pointsEarned:   data.pointsEarned,
        paymentInfo:    normalizePaymentInfo( data.paymentInfo || { } ),
        status:         data.orderStatus,
        totalAmount:    data.totalPrice,
        totalTaxes:     data.totalTaxes,
        userId:         data.userId,
        charges:        data.items.reduce( ( acc, item ) => {
            return acc = acc.concat( item.charges.map( normalizeCharge ) );
        }, [] ),
        packageOffer:   data.offer ? normalizePackageOffer( data.offer ) : { },
        billingDetails: data.billingDetails ?  normalizeBillingDetails( data.billingDetails ) : { },
        giftCard: data.giftCard ? normalizeGiftCard( data.giftCard ) : null
    };
}

function normalizePaymentInfo( info ) {
    return {
        card: {
            expireMonth:        info.cardExpireMonth,
            expireYear:         info.cardExpireYear,
            last4:              info.cardLast4,
            type:               info.cardType,
        },
    };
}

function normalizeBillingDetails( data ) {
    return {
        address: data.address || null,
        city: data.city || null,
        country: data.country || null,
        emailAddress: data.emailAddress || null,
        firstName: data.firstName || null,
        lastName: data.lastName || null,
        stateCode: data.stateCode || null,
        zipCode: data.zipCode || null,
    };
}