import currencyConstants from '@core/utils/constants/currency';
import currencySymbol from '@tenant/app/filters/currencySymbol';

export default function ( value, currency = currencyConstants.DEFAULT, options = { showSymbol: true } ) {
    const { showSymbol } = options;

    if ( showSymbol ) {
        const currencyFormatted = currencySymbol( currency );
        return `${value} (${currencyFormatted})`;
    }

    return value;
}