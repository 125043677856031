import { format as formatNumberUtils } from '@core/utils/numberUtils';
import numberConstants from './constants/number';

export const format = ( value, currency, options = { digits: 2, currencyDisplay: 'narrowSymbol' } ) => {
    const digits = options ? options.digits : 2;
    const currencyDisplay = isValidCurrencyDisplay( options?.currencyDisplay ) ? options.currencyDisplay : 'narrowSymbol';
    
    const formatNumeral = digits === 0 ? numberConstants.FORMAT.INTEGER : numberConstants.FORMAT.DEFAULT;


    try {
        if ( !currency ) {
            return formatNumberUtils ( value, formatNumeral );
        } else {
            return new Intl.NumberFormat(
                'en-US',
                {
                    style: 'currency',
                    currency,
                    currencyDisplay: currencyDisplay,
                    maximumFractionDigits: digits,
                    minimumFractionDigits: digits
                }
            ).format( value );
        }
    } catch {
        return formatNumberUtils ( value, formatNumeral );
    }
};

function isValidCurrencyDisplay ( option ) {
    const currencyDisplayOptions = [ 'symbol', 'narrowSymbol', 'code', 'name' ];
    return currencyDisplayOptions.includes( option );
}

export function getSymbolWithCurrency( currency ) {
    return `${getSymbol( currency )} ${currency}`;
}

export function getSymbol ( currency ) {
    try {
        return new Intl.NumberFormat( 'en-US', { style: 'currency', currency, currencyDisplay:'narrowSymbol' } )
            .formatToParts( 1 )
            .map( val => val.value )[ 0 ];
    } catch {
        return currency;
    }
}


export function formatDefault ( value, currency ) {
    return `${format( value, currency )} ${currency}`;
}

export function formatDefaultShort ( value, currency ) {
    return `${format( value, currency )}`;
}

export function formatDefaultShortNoDigits( value, currency ) {
    return `${format( Math.ceil( value ), currency, { digits: 0 } )}`;
}

export function formatRoundDownNoDigitsWithCurrency( value, currency ) {
    return `${format( Math.floor( value ), currency, { digits: 0 } )} ${currency}`;
}

export function formatCustom( value, currency, options = { digits: 2 } ) {
    return format( value, currency, options );
}


