export function getQueryParams( url ) {
    const hashIndex = url.indexOf( '#' );
    const urlString = hashIndex !== -1 ? url.substring( 0, hashIndex ) : url;
    
    const queryStringIndex = urlString.indexOf( '?' );
    if ( queryStringIndex === -1 ) {
        return {};
    }
    
    const paramArr = urlString.slice( queryStringIndex + 1 ).split( '&' );
    const params = {};
    paramArr.forEach( param => {
        const [ key, val ] = param.split( '=' );
        params[key] = decodeURIComponent( val );
    } );
    return params;
}

export function getQueryParamsCurrent ( ) {
    return getQueryParams( window.location.href );
}