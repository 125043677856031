<template>
    <div v-if="hasNotification"
        :class="{
            'gst-notification-container-mobile': $vuetify.breakpoint.smAndDown
        }"
        class="gst-notification-container">
        <transition-group name="fade"
            tag="div"
            appear>
            <v-card
                v-for="item in items"
                :key="item.uid"
                :class="item.className"
                class="card card-body u-box-shadow">
                <v-container class="pt-4 pb-0" card-toolbar>
                    <v-row no-gutters style="flex-wrap: nowrap;" class="pt-0">
                        <div class="d-flex flex-shrink-0 align-center">
                            <BaseIcon class="gst-notification-icon-svg" :symbol-id="item.symbolId" height="24" width="24" />
                        </div>
                        <v-flex class="title">
                            {{ item.title }}
                        </v-flex>
                        <v-flex shrink>
                            <BaseIcon class="gst-close-icon-svg u-mouse-pointer" symbol-id="icons--close" height="24" width="24" @click="close(item)" />
                        </v-flex>
                    </v-row>
                </v-container>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <v-card-text class="card-text" v-html="item.content" />
            </v-card>
        </transition-group>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        components: {
            BaseIcon
        },
        computed: {
            ...mapState( [ 'notification' ] ),
            ...mapGetters( {
                hasNotification: 'notification/hasNotification',
            } ),
            items( ) {
                return this.notification.items.filter( item => item.show ).map( item => {
                    let symbolId = '';
                    if ( item.icon === 'IconWarning' ) {
                        symbolId = 'icons--alert--warning';
                    }
                    if ( item.icon === 'IconSuccess' ) {
                        symbolId = 'icons--alert--success';
                    }
                    if ( item.icon === 'IconInfo' ) {
                        symbolId = 'icons--alert--info';
                    }
                    if ( item.icon === 'IconError' ) {
                        symbolId = 'icons--alert--error';
                    }

                    return {
                        ...item,
                        symbolId: symbolId
                    };
                } );
            }
        },
        methods: {
            ...mapActions( {
                close: 'notification/close'
            } )
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    $error: theme-color( 'error' );
    $success: theme-color( 'success' );
    $warning: theme-color( 'warning' );
    $info: theme-color( 'info' );

    .gst-notification-container {
        position: fixed;
        top: 20px;
        right: 20px;
        font-family: 'Roboto', sans-serif;
        z-index: $z-index-notification;
    }

    .gst-notification-container-mobile {
        top: unset;
        left: 50%;
        right: unset;
        bottom: 20px;
        z-index: $z-index-notification;
        transform: translateX( -50% );
    }

    .gst-notification-container .card {
        display: block;
        border: 8px;
        margin-bottom: theme-spacing( 5 );
        background-color: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );
        max-width: 380px;

        svg.gst-notification-icon-svg {
            margin-top: -3px;
            margin-right: theme-spacing( 2 );
        }

        button {
            margin-top: -3px;
        }

        .gst-close-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }
    }

    .gst-notification-container .card.info {
        border-left: 4px solid $info;

        .gst-notification-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: $info;
            }
        }
    }

    .gst-notification-container .card.danger {
        border-left: 4px solid $error;

        .gst-notification-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: $error;
            }
        }
    }

    .gst-notification-container .card.warning {
        border-left: 4px solid $warning;

        .gst-notification-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: $warning;
            }
        }
    }

    .gst-notification-container .card.success {
        border-left: 4px solid $success;

        .gst-notification-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: $success;
            }
        }
    }

    .gst-notification-container .card-body {
        width: 380px;
        padding: theme-spacing( 0 );
    }

    .gst-notification-container .card-toolbar {
        padding: theme-spacing( 4, 3 );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'bold' );
        overflow: hidden;
    }

    .gst-notification-container .card-text {
        padding-top: theme-spacing( 2 );
        padding-right: theme-spacing( 2 );
        padding-left: theme-spacing( 11 );
        font-size: font-size( 's' );
    }

    .gst-notification-container .notification-close {
        display: block;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.35s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    //customs
    .notification__card__payment-initiated {
        background-color: theme-color( 'warning' ) !important;
        color: theme-color( 'white' );
        border-color: theme-color( 'transparent' );
    }

</style>
