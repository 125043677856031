import normalizeResponsiveImage from './responsiveImage';
import normalizeHotelRoomRate from './hotelRoomRate';

export default function normalize( data = { }, accommodation = { } ) {
    return {
        id                     : data.roomCode,
        name                   : data.roomType,
        bedTypeCode            : data.bedTypeCode,
        standardNumBeds        : data.standardNumBeds,
        nonSmoking             : data.nonSmoking,
        rates                  : data.roomRates.map( rate => normalizeHotelRoomRate( rate ) ),
        amenities              : data.amenities ? data.amenities.map( item => normalizeAmenity( item ) ) : [ ],
        images                 : data.images.map( item => normalizeResponsiveImage( item ) ),
        accommodation
    };
}

function normalizeAmenity( data ) {
    return {
        name                   : data.name,
        quantity               : data.quantity,
        price                  : data.amount || 0,
        currency               : data.currency,
        included               : !!data.included,
    };
}