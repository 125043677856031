import Vue from 'vue';
import capitalizeFirst from '@core/filters/capitalizeFirst';
import city from '@core/filters/city';
import cityHTML from '@core/filters/cityHTML';
import venueInfoLocation from '@core/filters/venueInfoLocation';
import seat from '@core/filters/seat';
import seatLocation from '@core/filters/seatLocation';
import distance from '@core/filters/distance';
import distanceFrom from '@core/filters/distanceFrom';
import distanceToVenue from '@core/filters/distanceToVenue';
import number from '@core/filters/number';
import currency from './currency';
import cancellationPolicy from './cancellationPolicy';
import currencySymbolWithCurrency from './currencySymbolWithCurrency';
import currencySymbol from './currencySymbol';
import datePeriod from './datePeriod';
import eventPeriod from './eventPeriod';
import seatFullLocation from './seatFullLocation';
import appendCurrencySymbol from './appendCurrencySymbol';
import period from './period';
import date from './date';
import dateDayOfWeek from './dateDayOfWeek';
import dateMonth from './dateMonth';
import dateDay from './dateDay';
import time from './time';

Vue.filter( 'cancellationPolicy', cancellationPolicy );
Vue.filter( 'capitalizeFirst', capitalizeFirst );
Vue.filter( 'city', city );
Vue.filter( 'cityHTML', cityHTML );
Vue.filter( 'currencySymbolWithCurrency', currencySymbolWithCurrency );
Vue.filter( 'currencySymbol', currencySymbol );
Vue.filter( 'currencyFilter', currency );
Vue.filter( 'date', date );
Vue.filter( 'time', time );
Vue.filter( 'datePeriod', datePeriod );
Vue.filter( 'dateMonth', dateMonth );
Vue.filter( 'dateDayOfWeek', dateDayOfWeek );
Vue.filter( 'dateDay', dateDay );
Vue.filter( 'distance', distance );
Vue.filter( 'distanceFrom', distanceFrom );
Vue.filter( 'distanceToVenue', distanceToVenue );
Vue.filter( 'eventPeriod', eventPeriod );
Vue.filter( 'seat', seat );
Vue.filter( 'seatFullLocation', seatFullLocation );
Vue.filter( 'seatLocation', seatLocation );
Vue.filter( 'venueInfoLocation', venueInfoLocation );
Vue.filter( 'appendCurrencySymbol', appendCurrencySymbol );
Vue.filter( 'period', period );
Vue.filter( 'number', number );
