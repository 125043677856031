export function load( src, type = 'text/javascript', attrs = { async: true }, target = document.head ) {
    return new Promise( function ( resolve, reject ) {

        if ( document.querySelector( 'script[src="' + src + '"]' ) ) {
            resolve();

            return;
        }

        const el = document.createElement( 'script' );

        el.type = type;
        el.async = attrs.async;
        Object.keys( attrs ).forEach( attrKey => {
            el[attrKey] = attrs[attrKey];
        } );
        el.src = src;

        el.addEventListener( 'load', resolve );
        el.addEventListener( 'error', reject );
        el.addEventListener( 'abort', reject );

        target.appendChild( el );
    } );
}

export function unload ( src, target = document.head ) {
    return new Promise( function ( resolve, reject ) {
        const el = document.querySelector( 'script[src="' + src + '"]' );

        if ( !el ) {
            reject();

            return;
        }

        target.removeChild( el );

        resolve();
    } );
}