import apiConstants from '@core/utils/constants/api';
import {
    getDefaultGetParams as apiUtilsGetDefaultGetParams,
    cleanQueryParams as apiUtilsNormalizeQueryParams,
} from '@core/utils/apiUtils';
import { createApiService, fetchAPI } from '@core/api/_utils';
import searchConstants from '@core/utils/constants/search';

const defaultGetParam = apiUtilsGetDefaultGetParams( );

let api = {
    locations: {
        get: ( {
            keyword = defaultGetParam.keyword,
            countryCodes,
            limit = defaultGetParam.limit,
            offset = defaultGetParam.offset
        } = defaultGetParam ) => {
            return fetchAPI.getList(
                `${apiConstants.BASE_URL}/api/locations/suggestions`,
                apiUtilsNormalizeQueryParams( { limit, offset, keyword, countryCodes } )
            );
        },
        getClosestCity: ( {
            latitude,
            longitude,
            range = searchConstants.DEFAULT.LOCATION.RANGE.DEFAULT
        } = defaultGetParam ) => {
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/locations/closest-city`,
                { latitude, longitude, range }
            );
        }
    },
};

export default async function ( config ) {
    return createApiService( config, api );
}