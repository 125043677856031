import Vue from 'vue';
import VueRouter from 'vue-router';
import commonRoutes from '@tenant/app/routes/index';


export default {
    create: ( ) => {
        Vue.use( VueRouter );

        return new VueRouter( {
            mode: 'history',
            base: '/',
            routes: commonRoutes,
            scrollBehavior ( to, from ) {
                if ( to.hash ) {
                    return {
                        selector: to.hash,
                        offset: { x: 0, y: 60 }
                    };
                }
                if ( to.name === from.name ) {
                    return;
                }
                return { x: 0, y: 0 };
            }
        } );
    }
};
