const STORE_NAME = 'map';

import getDistance from 'geolib/es/getDistance';
import mapConstants from '@tenant/app/utils/constants/map';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        mapCenter: {
            latitude: 0,
            longitude: 0
        },
        searchCenter: {
            latitude: 0,
            longitude: 0
        }
    },
    mutations: {
        clear( state ) {
            state.mapCenter = {
                latitude: 0,
                longitude: 0
            };
            state.searchCenter = {
                latitude: 0,
                longitude: 0
            };
        },
        setMapCenter( state, mapCenter ) {
            state.mapCenter.latitude = mapCenter.latitude;
            state.mapCenter.longitude = mapCenter.longitude;
        },
        setSearchCenter( state, searchCenter ) {
            state.searchCenter.latitude = searchCenter.latitude;
            state.searchCenter.longitude = searchCenter.longitude;
        }
    },
    getters: {
        isNewSearchArea: state => {
            let distance = 0;
            if ( state.searchCenter.latitude && state.mapCenter.latitude ) {
                distance = getDistance( state.searchCenter, state.mapCenter );
            }

            return distance > mapConstants.DEFAULT_CONFIG.DISTANCE_TRIGGER_NEW_SEARCH_METERS;
        }
    },
    actions: {
        clear( { commit } ) {
            commit( 'clear' );
        },
        setMapCenter( { commit }, mapCenter ) {
            commit( 'setMapCenter', mapCenter );
        },
        setSearchCenter( { commit }, searchCenter ) {
            commit( 'setSearchCenter', searchCenter );
        }
    }
};
