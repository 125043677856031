<template>
    <v-app>
        <TheHeader />
        <v-main class="main" :style="mainStyle">
            <!-- Provides the application the proper gutter -->
            <v-container fluid class="pa-0">
                <slot></slot>
            </v-container>
        </v-main>
        <TheFooter />
    </v-app>
</template>

<script>
    import TheFooter from './components/TheFooter.vue';
    import TheHeader from './components/TheHeader.vue';

    export default {
        name: 'App',
        components: {
            TheHeader,
            TheFooter
        },
        computed: {
            mainStyle() {
                return {
                    paddingTop: '50px'
                };
            }
        }
    };
</script>
