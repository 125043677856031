import { getLang as documentUtilsGetLang } from '@core/utils/documentUtils';
import {
    isSameMonth as dateUtilsIsSameMonth,
    isSameYear as dateUtilsIsSameYear,
    format as dateUtilsFormat
} from '@tenant/app/utils/dateUtils';

export default function reservationDatePeriod( startDate, endDate, isShortFormat = false, language = documentUtilsGetLang( ) ) {

    const isSameMonth = dateUtilsIsSameMonth( startDate, endDate );
    const isSameYear = dateUtilsIsSameYear( startDate, endDate );

    let format = {
        period: 'eee, MMM d',
        periodSameMonth:  'eee, MMM d, yyyy',
        periodFull: 'eee, MMM d, yyyy'
    };

    if ( isShortFormat ) {
        format = {
            period: 'MMM d',
            periodSameMonth:  'd, yyyy',
            periodFull: 'MMM d, yyyy'
        };
    }

    if ( !isSameYear ) {
        const startDateFormated = dateUtilsFormat( startDate, format.periodFull, language );
        const endDateFormated = dateUtilsFormat( endDate, format.periodFull, language );
        return `${startDateFormated} - ${endDateFormated}`;
    }

    if ( !isSameMonth ) {
        const startDateFormated = dateUtilsFormat( startDate, format.period, language );
        const endDateFormated = dateUtilsFormat( endDate, format.periodFull, language );
        return `${startDateFormated} - ${endDateFormated}`;
    }

    const startDateFormated = dateUtilsFormat( startDate, format.period, language );
    const endDateFormated = dateUtilsFormat( endDate, format.periodSameMonth, language );
    return `${startDateFormated} - ${endDateFormated}`;
}