import { load as loadScript, unload as unloadScript } from '@core/utils/scriptUtils';

const LoadScriptPlugin = {
    install( Vue ) {
        if ( this.installed ) {
            return;
        }

        this.installed = true;

        Vue.loadScript = Vue.prototype.$loadScript = loadScript;
        Vue.unloadScript = Vue.prototype.$unloadScript = unloadScript;
    }
};

export default LoadScriptPlugin;