export default {
    APPEND_TO_LIST: 'APPEND_TO_LIST',
    SET_LIST: 'SET_LIST',
    CLEAR_LIST: 'CLEAR_LIST',
    SET_DETAIL: 'SET_DETAIL',
    SET_LOADING: 'SET_LOADING',
    SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
    SET_REFRESH: 'SET_REFRESH',
    SET_ALL_ITEMS_LOADED: 'SET_ALL_ITEMS_LOADED',
    CREATE_ITEM: 'CREATE_ITEM',
    DELETE_ITEM: 'DELETE_ITEM',
    UPDATE: 'UPDATE',
    UPDATE_ITEM: 'UPDATE_ITEM',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_TIMESTAMP: 'UPDATE_TIMESTAMP',
    CLEAR: 'CLEAR',
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
};