import { differenceInCalendarDays } from '@core/utils/dateUtils';
import {
    parseDateString as dateUtilsParseDateString
} from '@core/utils/dateUtils';
import normalizeGuest from './guest';

export default function normalize( stayRange = { }, roomCriteria = { } ) {
    const ret = {
        startDateTime: stayRange.checkin ? dateUtilsParseDateString( stayRange.checkin ) : null,
        endDateTime: stayRange.checkout ? dateUtilsParseDateString( stayRange.checkout ) : null,
        guests: normalizeGuest( roomCriteria ),
        roomsCount: roomCriteria.roomCount || 0,

    };
    ret.nights = differenceInCalendarDays( ret.endDateTime, ret.startDateTime );

    return ret;
}