let instances = {};

const service = {
    getInstance ( key ) {
        /**
         * Ionutt 2022-08-30
         * This is not working in IE11. The entire app is not working on IE11
         * If this is requested we will switch to a polyfill
         */
        const controller = new AbortController( );

        instances[ key ] = instances[ key ] ? instances[ key ] : [];
        instances[ key ].push( controller );

        return controller;
    },
    getToken( key ) {
        return service.getInstance( key ).signal;
    },
    clear( key, reason ) {
        const hasKey = instances.hasOwnProperty( key );

        if ( hasKey && Array.isArray( instances[ key ] ) ) {
            instances[ key ].map( controller => {
                controller.abort( reason );
            } );
            instances[ key ] = [];
        }
    },
};

export default service;
