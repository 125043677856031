/**
 * @typedef {Object} CartInvoice
 * @param {String} data.id 
 * @param {Number} data.amount 
 * @param {String} data.currency 
 * @param {String} data.status 
 */

/**
 * 
 * @param {Object} data 
 * @param {String} data.id 
 * @param {Number} data.amount 
 * @param {String} data.currency 
 * @param {String} data.status 
 * @returns CartInvoice
 */
export default function ( data ) {
    return {
        id: data.id || '',
        amount: data.amount || 0,
        currency: data.currency,
        status: data.status
    };
} 