import qs from 'qs';
import apiConstants from '@core/utils/constants/api';
import {
    getDefaultGetParams as apiUtilsGetDefaultGetParams,
} from '@core/utils/apiUtils';
import { createApiService, fetchAPI } from '@core/api/_utils';
import LocalStorageService from '@tenant/app/services/LocalStorageService';
import { normalizeQueryParams } from './_utils.js';

const defaultGetParam = apiUtilsGetDefaultGetParams( );

const paramsSerializerFn = params => qs.stringify( params, { arrayFormat: 'repeat', indices: false } );

let api = {
    hotels: {
        get: (
            {
                limit = defaultGetParam.limit,
                offset = defaultGetParam.offset,
            },
            {
                startDate = '',
                endDate = '',
                guests = { },
                roomsCount,
                latitude,
                longitude,
                range,
                countryCode
            },
            config = { } ) => {
            const userToken = LocalStorageService.userToken.get( );
            const userRuleSet = LocalStorageService.userRuleSet.get( );
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/hotels`,
                normalizeQueryParams( {
                    token: userToken,
                    userRuleSet: userRuleSet,
                    startDate,
                    endDate,
                    guests,
                    roomsCount,
                    latitude,
                    longitude,
                    range,
                    countryCode,
                    limit,
                    offset
                } ),
                {},
                {
                    ...config,
                    paramsSerializer: paramsSerializerFn
                }
            );
        },
        /**
         * Get hotel offers by event id.
         * Currently used only for PostPurchase HOTEL_ONLY offers
         * copied from apiServicePackageOffers
         */
        getOffersByEventId: (
            {
                eventId,
                offerType
            } = { },
            config = { },
            headers = { }
        ) => {
            return fetchAPI.getList(
                `${apiConstants.BASE_URL}/api/product-package/event/${eventId}/hotels`,
                { offerType },
                headers,
                config
            );
        },
        getByProduct: ( productId,
            {
                limit = defaultGetParam.limit,
                offset = defaultGetParam.offset,
            },
            {
                startDate = '',
                endDate = '',
                guests = { },
                roomsCount,
                range
            },
            config = { } ) => {
            const userToken = LocalStorageService.userToken.get( );
            const userRuleSet = LocalStorageService.userRuleSet.get( );
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/product/hotels`,
                normalizeQueryParams( {
                    productId,
                    token: userToken,
                    userRuleSet: userRuleSet,
                    startDate,
                    endDate,
                    guests,
                    roomsCount,
                    limit,
                    offset,
                    range
                } ),
                {},
                {
                    ...config,
                    paramsSerializer: paramsSerializerFn
                }
            );
        },
        one: ( id, {
            startDate = '',
            endDate = '',
            guests = { },
            roomsCount
        }, dataProvider ) => {
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/hotels/${id}`,
                normalizeQueryParams( {
                    startDate,
                    endDate,
                    guests,
                    roomsCount,
                    externalSystem: dataProvider
                } ),
                {},
                {
                    paramsSerializer: paramsSerializerFn
                }
            );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
