<template>
    <div>
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid class="py-0 px-0">
                <slot></slot>
            </v-container>
        </v-main>
        <ManageCookiesButton floating />
    </div>
</template>

<script>
    import ManageCookiesButton from '@tenant/app/components/misc/ManageCookiesButton.vue';

    export default {
        name: 'App',
        components: { ManageCookiesButton }
    };
</script>
