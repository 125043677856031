<template>
    <BaseIcon class="gst-ticketmaster-travel-logo" symbol-id="t-travel-logo" />
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'TheLogo',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss" scoped>
    .gst-ticketmaster-travel-logo {
        flex-grow: 0;
        flex-shrink: 0;
    }
</style>