import appConstants from './constants/app';
import {
    isSameVersion as appUtilsIsSameVersion
} from './appUtils';

export function saveSetting( setting, value, ttl ) {
    if ( ttl ) {
        const now = new Date();
        value = {
            value: value,
            _expire: now.getTime() + ttl,
        };
    }
    localStorage.setItem( setting, JSON.stringify( value ) );
}

export function getSetting ( setting ) {
    try {
        const storedValue = localStorage.getItem( setting );
        if ( !storedValue ) {
            return null;
        }
        const value = JSON.parse( storedValue );
        const now = new Date();

        if ( value._expire && now.getTime() > value._expire ) {
            localStorage.removeItem( setting );
            return null;
        }

        return value;
    } catch ( e ) {
        return null;
    }
}

export function saveSettingWithAppVersion ( setting, value ) {
    saveSetting( setting, { version: appConstants.VERSION, value } );
}

export function getSettingSameAppVersion ( setting, versionLevel = 2 ) {
    const data = getSetting ( setting );

    return data && data.version && appUtilsIsSameVersion( data.version, versionLevel ) ? data.value : null;
}

const AUTHENTICATION_KEY = 'ssoAuthentication'; //don't change the value

export function setAuthentication( accessToken, refreshToken ) {
    localStorage.setItem( AUTHENTICATION_KEY, JSON.stringify( { accessToken, refreshToken } ) );
}

export function getAuthentication( ) {
    try {
        const value = JSON.parse( localStorage.getItem( AUTHENTICATION_KEY ) );

        if ( !value ) {
            return false;
        }
        const { accessToken, refreshToken } = value;

        if ( accessToken && refreshToken  ) {
            return { accessToken, refreshToken };
        }
        return false;
    } catch ( e ) {
        clearAuthentication( );
        return false;
    }
}

export function clearAuthentication ( ) {
    localStorage.removeItem( AUTHENTICATION_KEY );
}
