import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import merge from 'lodash/merge';
import LogService from '@core/services/LogService';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '@tenant/app/utils/constants/i18n';

const namespaces = [ 'main', 'shared', '_common' ];

const getCoreLocalesFiles = ( language ) => {
    const files = namespaces.map( async namespace => {
        try {
            return ( {
                namespace: namespace,
                promise: await import( '@core/locales/' + namespace + '/' + language )
            } );
        } catch ( e ) {
            return null;
        }

    } );

    return Promise.all( files ).then( results => results.filter( result => result !== null ) );
};

const getCustomLocalesFiles = ( locale ) => {
    const files = namespaces.map( async namespace => {
        try {
            return ( {
                namespace: namespace,
                promise: await import( '@tenant/app/locales/' + namespace + '/' + locale )
            } );
        } catch ( e ) {
            return null;
        }

    } );

    return Promise.all( files ).then( results => results.filter( result => result !== null ) );
};

const getResources2 = async ( ) => {
    let ret = {};

    for ( const locale of SUPPORTED_LOCALES ) {
        const lang = locale.split( '-' )[0];
        const coreLocales = await getCoreLocalesFiles( lang );
        const defaultLocales = coreLocales.reduce( ( acc, localeItem ) => {
            if ( localeItem ) {
                acc[localeItem.namespace] = localeItem.promise.default;
            }
            return acc;
        }, {} );

        const customFiles = await getCustomLocalesFiles( locale );
        const customLocales = customFiles.reduce( ( acc, localeItem ) => {
            if ( localeItem ) {
                acc[localeItem.namespace] = localeItem.promise.default;
            }
            return acc;
        }, {} );

        ret[locale] = {
            _common: customLocales._common ? merge( defaultLocales['_common'], customLocales._common ) : defaultLocales['_common'],
            shared: customLocales.shared ? merge( defaultLocales['shared'], customLocales.shared ) : defaultLocales['shared'],
            main: customLocales.main
        };

    }

    return ret;
};

export default {
    create: async ( ) => {
        const resources = await getResources2( );

        i18next
            .init( {
                debug: process.env.NODE_ENV !== 'production',
                lng: DEFAULT_LOCALE,
                fallbackLng: DEFAULT_LOCALE,
                supportedLngs: SUPPORTED_LOCALES,
                ns: [
                    'home',
                    'shared',
                    'main'
                ],
                resources,
                parseMissingKeyHandler: ( ) => {
                    if ( process.env.NODE_ENV === 'production' )
                        LogService.warn( 'Missing Key from translation file' );
                    return 'Invalid Key';
                },
                interpolation: {
                    format: ( value, format ) => {
                        switch ( format ) {
                        case 'uppercase': 
                            return value.toUpperCase( );
                        case 'lowercase':
                            return value.toLowerCase( );
                        default:
                            return value;
                        }
                    }
                }
            } );

        Vue.use( VueI18Next );

        return new VueI18Next( i18next );
    }
};
