import purchaseFlowConstants from '@core/utils/constants/purchaseFlow';

export function normalizeEventPurchaseFlow( purchaseFlow ) {
    switch ( purchaseFlow ) {
    case 'GST_ONLY':
        return purchaseFlowConstants.TYPES.DIRECT;
    case 'TM_ONLY':
        return purchaseFlowConstants.TYPES.PROVIDER;
    case 'BOTH':
        return purchaseFlowConstants.TYPES.BOTH;
    default:
        return null;
    }
}