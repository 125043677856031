export default {
    TYPES: {
        /**
         * SSO for flyinblue
         */
        SSO: 'SSO_FB',
        SSO_DEFAULT: 'SSO',
    },
    STATES: {
        NEEDS_LOGOUT: 'LOG OUT',
        REDIRECTING: 'REDIRECTING',
        CREATE_WALLET_WITH_MV_DELEGATE_SUCCESS: 'CREATE_WALLET_WITH_MV_DELEGATE_SUCCESS'
    },
    RESPONSE_CODES: {
        SERVER_ERROR: 'SERVER_ERROR'
    }
};
