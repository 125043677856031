import fetch from '@core/utils/fetch';
import { formatQueryParam as dateUtilsFormatQueryParam } from '@core/utils/dateUtils';
import apiConstants from './constants/api';

const HTTP_STATUS_SUCCESS = [ 200, 201 ];

function isSuccessStatus( status ) {
    return HTTP_STATUS_SUCCESS.includes( status );
}

/**
 * @typedef {Object} Response
 * @property {Boolean} success - depending on the error
 * @property {Object} data - data from the response
 * @property {String} title - title of the error
 * @property {String} message - message of the error
 * @property {Object} detail - detail object of the error
 * @property {Object} details - detail array of the error
 * @property {Number} status - status of the error
 * @property {Number} code - code of the error
 * @property {?} cancel - cancel for axios
 */

export function normalizeListSuccess ( { data } ) {
    return {
        success:    true,
        data: {
            list: data.items,
            totalCount: data.totalCount,
        },
        _originalData: {
            ...data
        }
    };
}

export function normalizeSuccess ( { data } ) {
    return {
        success:    true,
        data
    };
}

export function normalizeSuccessRaw ( response ) {
    return {
        success: true,
        data: response
    };
}

/**
 * Normalize error
 * @param {Object} param0
 * @param {String} param0.title - title of the error
 * @param {string} param0.message - message detail
 * @param {Array} param0.violations - detail array with violations for different fields ( field and name )
 * @param {number} param0.status - status
 * @param {Number} param0.code - error code
 * @param {Object} param0.data - data related to error
 * @param {?} param0.cancel
 *
 * @returns {Response}
 */

export function normalizeError ( { title, detail, data, violations, status, cancel = false, code = null } ) {
    return {
        success:    false,
        title:      title,
        message:    detail,
        //example: used for error with code like 40076
        detail:     data,
        //Note: not used until 2023-10-23
        details:   ( violations && violations.length ) ? violations.map( ( { field, message } ) => {
            return {
                field,
                message: message
            };
        } ) : null,
        status:     status,
        code:       code,
        cancel
    };
}

export function getDefaultGetParams ( ) {
    return {
        keyword:    apiConstants.GET_DEFAULT_PARAMS.KEYWORD,
        limit:      apiConstants.GET_DEFAULT_PARAMS.LIMIT,
        offset:     apiConstants.GET_DEFAULT_PARAMS.OFFSET
    };
}

export function getDefaultGetParamsWithCount ( ) {
    return {
        ...getDefaultGetParams( ),
        count: false
    };
}

export function cleanQueryParams ( params ) {
    const ret = { ...params };

    for ( let key in ret ) {
        if ( ret[key] === null || ret[key] === undefined || ret[key] === '' ) {
            delete ret[key];
        }
    }

    return ret;
}

export function normalizeQueryParams ( data ) {
    const ret = { ...data };

    ret.startDate = ret.startDate && dateUtilsFormatQueryParam ( ret.startDate );
    ret.endDate  =  ret.endDate && dateUtilsFormatQueryParam ( ret.endDate );

    return cleanQueryParams( ret );
}

export function normalizeQueryParamsWithCity ( { city, countryCode, ...rest } ) {
    if ( city && city.name ) {
        return normalizeQueryParams( {
            ...rest,
            cityName: city.name,
            stateCode: city.stateCode,
            countryCode: city.countryCode,
        } );
    } else {
        return normalizeQueryParams( {
            ...rest,
            countryCode: countryCode,
        } );
    }
}

export function normalizeQueryParamsWithCityLocation ( { city, countryCode, ...rest } ) {
    if ( city && !city.existsInApi && city.location && city.location.latitude && city.location.longitude ) {
        return normalizeQueryParams( {
            ...rest,
            lat: city.location.latitude,
            long: city.location.longitude,
            range: city.location.range || null,
        } );
    }
    if ( city && city.name ) {
        return normalizeQueryParams( {
            ...rest,
            cityName: city.name,
            stateCode: city.stateCode,
            countryCode: city.countryCode,
        } );
    }

    return normalizeQueryParams( {
        ...rest,
        countryCode: countryCode,
    } );
}

export function checkStatusList ( response ) {
    let data = response.data;
    return response.status === 200 ? normalizeListSuccess ( { data } ) : normalizeError ( data );
}

export function checkStatus ( response ) {
    let data = response.data;
    return isSuccessStatus( response.status ) ? normalizeSuccess ( { data } ) : normalizeError ( data );
}

export function checkStatusRaw ( response ) {
    let data = response.data;
    return isSuccessStatus( response.status ) ? normalizeSuccessRaw ( data ) : normalizeError ( data );
}

export function checkError ( error ) {
    if ( fetch.isRequestCancel( error ) ) {
        return normalizeError ( { cancel: true } );
    }

    return normalizeError  ( error.response && error.response.data ? error.response.data : error );
}
