export function normalizeLocation( data ) {
    return {
        city: data.city || null,
        country: data.country || null,
        countryCode: data.countryCode || null,
        latitude: data.latitude || null,
        longitude: data.longitude || null,
        name: data.name || null,
        state: data.state || null,
        stateCode: data.stateCode || null,
        timezone: data.timezone || null,
        type: data.type || null,
    };
}
