export default {
    equal: {
        LOCALHOST: 'localhost',
        TEST: 'ticketmaster.ui.test.lifestylesolutionsteam.com',
        UAT: [
            'ticketmaster.ui.uat.lifestylesolutionsteam.com',
            'tmtravel.lifestylesolutionsteam.com'
        ],
        PRODUCTION: 'travel.ticketmaster.com',
    },
    include: {
        LOCALHOST: 'localhost',
        TEST: 'ticketmaster.ui.test.lifestyle',
        UAT: [
            'ticketmaster.ui.uat.lifestylesolutionsteam',
            'tmtravel.lifestylesolutionsteam.com'
        ],
        PRODUCTION: 'travel.ticketmaster.com',
    },
    regex: {
        LOCALHOST: new RegExp( '^localhost' ), // regex start with ( ^ )
        TEST: new RegExp( '(^ticketmaster.ui.test).*lifestyle' ), // regex start with ( ^ ) and contains ( .* )
        UAT: [
            new RegExp( '(^ticketmaster.ui.uat).*lifestylesolutionsteam' ),
            new RegExp( '(^tmtravel).*lifestylesolutionsteam' ),
        ], // regex start with ( ^ ) and contains ( .* )
        PRODUCTION: new RegExp( '(^travel).*ticketmaster' ), // regex start with ( ^ ) and contains ( .* )
    }
};
