import apiConstants from '@core/utils/constants/api';
import fetch from '@core/utils/fetch';
import {
    checkStatusList as apiUtilsCheckStatusList,
    checkStatusRaw as apiUtilsCheckStatusRawList,
    checkStatus as apiUtilsCheckStatus,
    checkError as apiUtilsCheckError
} from '@core/utils/apiUtils';
import { getSessionId as sessionUtilsGetSessionId } from '@core/utils/sessionUtils';
import AuthenticationService from '@core/services/AuthenticationService';

let sessionId;

export function getApiCommonHeaders ( ) {
    const store = window.$store;
    const apiPartner = store.state.appState.api.partner;
    const apiMockData = store.state.appState.api.mockData;
    const user = store.state.user;
    const userSessionId = user.profile.sessionId;
    const referralId = user.wallet ? user.wallet.referralId : null;
    const accessToken = AuthenticationService.getAccessToken( );
    let ret = {};

    if ( !sessionId ) {
        sessionId = sessionUtilsGetSessionId( );
    }

    apiPartner === null || ( ret[apiConstants.HEADERS.GST_PARTNER_API] = apiPartner );
    apiMockData === false || ( ret[apiConstants.HEADERS.GST_MOCK_DATA] = apiMockData );

    ret[apiConstants.HEADERS.GST_LANGUAGE] = document.documentElement.getAttribute( 'lang' );
    ret[apiConstants.HEADERS.GST_SESSION_ID] = userSessionId || sessionId;
    ret[apiConstants.HEADERS.GST_ANALYTICS] = 0;

    if ( referralId ) {
        ret[apiConstants.HEADERS.GST_UID] = referralId;
    }
    if ( accessToken ) {
        ret[ 'Bearer-Authorization' ] = `Bearer ${ accessToken }`;
    }

    return ret;
}

const checkError = ( error ) => {
    if ( error.response?.status === 403 ) {
        window.$store.dispatch( 'user/invalidate' );
    }
    return apiUtilsCheckError( error );
};

export const fetchAPI = {
    getList: ( url, params = { }, headers = { }, { cancelToken, paramsSerializer } = { } ) => {
        return fetch
            .get( url, { headers: { ...getApiCommonHeaders( ), ...headers }, params, signal: cancelToken, paramsSerializer } )
            .then ( apiUtilsCheckStatusList )
            .catch ( checkError );
    },
    getListRaw: ( url, params = { }, headers = { }, { cancelToken, paramsSerializer } = { } ) => {
        return fetch
            .get( url, { headers: { ...getApiCommonHeaders( ), ...headers }, params, signal: cancelToken, paramsSerializer } )
            .then ( apiUtilsCheckStatusRawList )
            .catch ( checkError );
    },
    get: ( url, params = { }, headers = { }, { cancelToken, paramsSerializer } = { } ) => {
        return fetch
            .get( url, { headers: { ...getApiCommonHeaders( ), ...headers }, params, signal: cancelToken, paramsSerializer } )
            .then ( apiUtilsCheckStatus )
            .catch ( checkError );
    },
    post: ( url, data, headers, { cancelToken } = { } ) => {
        return fetch
            .post( url, data, { headers: Object.assign( {}, headers, getApiCommonHeaders( ) ), signal: cancelToken } )
            .then ( apiUtilsCheckStatus )
            .catch ( checkError );
    },
    put: ( url, data, headers, { cancelToken } = { } ) => {
        return fetch
            .put( url, data, { headers: Object.assign( {}, headers, getApiCommonHeaders( ) ), signal:cancelToken } )
            .then ( apiUtilsCheckStatus )
            .catch ( checkError );
    },
    patch: ( url, data, headers, { cancelToken } = { } ) => {
        return fetch
            .patch( url, data, { headers: Object.assign( {}, headers, getApiCommonHeaders( ) ), signal:cancelToken } )
            .then ( apiUtilsCheckStatus )
            .catch ( checkError );
    },
    delete: ( url, data, headers, { cancelToken } = { } ) => {
        return fetch
            .delete( url, data, { headers: Object.assign( {}, headers, getApiCommonHeaders( ) ), signal: cancelToken } )
            .then ( apiUtilsCheckStatus )
            .catch ( checkError );
    },
};

export async function createApiService ( config, api ) {
    let mockup;

    switch( config ) {
    case 'mockup':
        mockup = await import( '@core/api/apiServiceMock' );

        return mockup.default;
    case 'mockupAndApi':
        mockup = await import ( '@core/api/apiServiceMock' );

        return {
            ...mockup.default,
            ...api,
        };
    case 'apiAndMockup':
        mockup = await import ( '@core/api/apiServiceMock' );

        return {
            ...api,
            ...mockup.default,
        };
    default:
        return api;
    }
}
