import i18next from 'i18next';
import apiMapLocationConstants from '@core/utils/constants/apiMapLocation';
import LogService from '@core/services/LogService';
import fetch from '@core/utils/fetch';

export default {
    city: {
        async getByPosition( latitude, longitude ) {
            const accessToken = apiMapLocationConstants.CONFIG.MAPBOX.apiKey;
            const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;

            try {
                const response = await fetch
                    .get(
                        geocodingUrl
                    )
                    .then( ( { data } ) => { return { success: true, data }; } )
                    .catch( e => {
                        LogService.debug( 'Error getting MapBox Direction:', e.message );
                        return { success: false, message: e.message, error: e }; } 
                    );

                if ( response ) {
                    const features = response.data.features;

                    const data = {
                        name: features.find( ( feature ) => feature.id.includes( 'place' ) ).text,
                        countryCode: features.find( ( feature ) => feature.id.includes( 'country' ) ).properties.short_code.toUpperCase( ),
                        stateCode: features.find( ( feature ) => feature.id.includes( 'region' ) ).properties.short_code.split( '-' )[ 1 ]
                    };
                   
                    return ( {
                        success: true,
                        data
                    } );
                } else {
                    return( {
                        success: false,
                        message: i18next.t( '_common:messages.apiMapLocationError' )
                    } );
                }
            } catch ( error ) {
                LogService.debug( 'problems retriving city from apiLocation ' + error );
                return( {
                    success: false,
                    message: i18next.t( '_common:messages.apiMapLocationError' )
                } );
            }
        }
    }
};
