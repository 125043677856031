import roomRateMealPlan from '@core/utils/constants/roomRateMealPlan';

export const getKeyByValue = ( value ) => {
    const types = roomRateMealPlan.TYPES;

    return Object.keys( types ).find( key => types[ key ] === value );
};

export const isWithoutMealPlan = ( value ) => {
    const key = getKeyByValue( value );

    return !!roomRateMealPlan.NO_MEAL_PLAN_TYPES.find( item => item === key );
};