import apiConstants from '@core/utils/constants/api';
import {
    getDefaultGetParams as apiUtilsGetDefaultGetParams,
    normalizeQueryParams as apiUtilsNormalizeQueryParams,
    checkStatusRaw as apiUtilsCheckStatusRaw,
    checkError as apiUtilsCheckError
} from '@core/utils/apiUtils';
import fetch from '@core/utils/fetch';
import { generateSessionId as sessionUtilsGenerateSessionId } from '@core/utils/sessionUtils';
import { createApiService, fetchAPI, getApiCommonHeaders } from '@core/api/_utils';

const defaultGetParam = apiUtilsGetDefaultGetParams( );

let api = {
    user: {
        /**
         * Get user information ( new version of the getUserWallet function )
         *
         * @returns {*}
         */
        get: ( ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/user` );
        },
        getSession: ( ) => {
            const headers = { };

            headers[ apiConstants.HEADERS.GST_SESSION_ID ] = sessionUtilsGenerateSessionId( );
            headers[ apiConstants.HEADERS.GST_ANALYTICS ] = 1;

            return fetch
                .request( `${apiConstants.BASE_URL}/api/user/session`, {
                    type: 'GET',
                    headers: { ...getApiCommonHeaders( ), ...headers }
                } )
                .then ( apiUtilsCheckStatusRaw )
                .catch ( apiUtilsCheckError );
        },
        getAnalytics: ( { limit = defaultGetParam.limit } ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/user/analytics`, apiUtilsNormalizeQueryParams( { limit } ) );
        },
        getRecentlyViewed: ( { limit = defaultGetParam.limit } ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/user/analytics/recent`, apiUtilsNormalizeQueryParams( { limit } ) );
        },
        deleteRecentlyViewed( items ) {
            return fetchAPI.delete( `${apiConstants.BASE_URL}/api/user/analytics`, { items } );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
