import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import PrismicVue from '@prismicio/vue';

/**
 * Modules
*/

/****** Prismic ( load plugin and load preview script ) ***********/
Vue.use( PrismicVue, {
    endpoint: prismicUtilsGetApiUrl( ),
    apiOptions: {}
} );

const prismicPreviewScriptUrl = prismicUtilsGetPreviewScriptUrl( );
prismicPreviewScriptUrl && loadScript( prismicPreviewScriptUrl );

/****** Modal Plugin ( vuetify modals used as an service ) ***********/

Vue.use( modalPlugin, {
    modalProps: {
        overlayOpacity: 0.8
    },
    modalBottomProps: {
        overlayOpacity: 0.8
    }
} );
import Hotjar from 'vue-hotjar';
// eslint-disable-next-line import/order
import { getConfig as hotjarUtilsGetConfig } from '@tenants/ticketmaster/app/utils/hotjarUtils';

const hotjarConfig = hotjarUtilsGetConfig();
Vue.use( Hotjar, hotjarConfig );

/****** Test Id Plugin ***********/

Vue.use( testIdPlugin );

/****** Device Plugin ***********/

Vue.use( devicePlugin );

/****** Load Script Plugin ***********/

Vue.use( loadScriptPlugin );

/****** Vue Scroll to ***********/
import VueScrollTo from 'vue-scrollto';

Vue.use( VueScrollTo );

/****** Vuelidate plugin ( validations ) ***********/
import Vuelidate from 'vuelidate';

Vue.use( Vuelidate );

/****** VUE INFINITE SCROLL ***********/
import infiniteScroll from 'vue-infinite-scroll';

Vue.use( infiniteScroll );

import devicePlugin from '@core/plugins/DevicePlugin';
import testIdPlugin from '@core/plugins/TestIdPlugin';
import modalPlugin from '@core/plugins/modal/modalPlugin';
import loadScriptPlugin from '@core/plugins/LoadScriptPlugin';
import '@core/scss/mixins/_z-index-export-js.scss';
import { load as loadScript } from '@core/utils/scriptUtils';
import '@tenant/app/modules/hotel/module';
import '@tenant/app/filters';
import { getApiUrl as prismicUtilsGetApiUrl, getPreviewScriptUrl as prismicUtilsGetPreviewScriptUrl } from '@tenant/app/utils/prismicUtils';
