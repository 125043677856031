import Vue from 'vue';
import date from '@tenant/app/filters/date';
import reservationDatePeriod from './reservationDatePeriod';
import bundleProductsQuantity from './bundleProductsQuantity';
import roomAmenity from './roomAmenity';
import roomName from './roomName';
import roomNameAndQuantity from './roomNameAndQuantity';
import roomRateFee from './roomRateFee';
import fullLocation from './fullLocation';
import mealPlan from './mealPlan';

Vue.filter( 'bundleProductsQuantity', bundleProductsQuantity );
Vue.filter( 'reservationDatePeriod', reservationDatePeriod );
Vue.filter( 'roomName', roomName );
Vue.filter( 'roomNameAndQuantity', roomNameAndQuantity );
Vue.filter( 'mealPlan', mealPlan );
Vue.filter( 'roomAmenity', roomAmenity );
Vue.filter( 'date', date );
Vue.filter( 'roomRateFee', roomRateFee );
Vue.filter( 'fullLocation', fullLocation );
