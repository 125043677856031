import Schema from 'validate';
import i18next from 'i18next';

import LogService from '@core/services/LogService';
import {
    setLang as documentUtilsSetLang
} from '@core/utils/documentUtils';
import apiServiceToken from '@tenant/app/api/apiServiceToken';
import { SUPPORTED_LOCALES } from '@tenant/app/utils/constants/i18n';
// import {
//     isProduction as environmentUtilsIsProduction
// } from '@tenant/app/utils/environmentUtils';

const STORE_NAME = 'appState';

const DEFAULT_STATE = {
    language: 'en',
    locale: 'en-US',
    loading: false,
    api: {
        partner: null,
        mockData: false
    },
    forceDynamicFlow: false,
    hamburgerMenuVisible: true,
    tokenStatus: null
};

export default {
    name: STORE_NAME,
    namespaced: true,
    state: DEFAULT_STATE,
    getters: {
        getLanguage: ( state ) => state.language,
        getLocale: ( state ) => state.locale,
        tenantCountry: ( state, getters, rootState ) => {
            return rootState.events.detail.venueCountry || 'US';
        },
        isHamburgerMenuVisible: ( state ) => {
            return state.hamburgerMenuVisible;
        },
        language: ( state ) => {
            return state.language;
        },
        tokenStatus: ( state ) => {
            return state.tokenStatus;
        }
    },
    mutations: {
        setLanguage( state, language ) {
            state.language = language;
        },
        setLocale( state, locale ) {
            state.locale = locale;
        },
        setApiPartner( state, partner ) {
            state.api.partner = partner;
        },
        setApiMockData( state, value ) {
            state.api.mockData = value;
        },
        setLoading( state, loading ) {
            state.loading = loading;
        },
        setHamburgerMenuVisible ( state, value ) {
            state.hamburgerMenuVisible = value;
        },
        setTokenStatus( state, tokenStatus ) {
            state.tokenStatus = tokenStatus;
        },
    },
    actions: {
        setLocale( { state, commit }, locale ) {
            if ( locale && SUPPORTED_LOCALES.includes( locale ) ) {
                if ( i18next.language !== locale ) {
                    i18next.changeLanguage( locale );
                }
                documentUtilsSetLang( locale );
                if ( state.locale !== locale ) {
                    commit( 'setLocale', locale );
                    commit( 'setLanguage', locale.split( '-' )[0] );
                }
            }
        },
        getTokenStatus: async( { commit }, uid ) => {
            const api = await apiServiceToken( '' );
            const response = await api.tokenStatus.get( uid );
            if ( response.success ) {
                commit( 'setTokenStatus', response.data.status );
            }
        },
        setApiPartner( { commit }, partner ) {
            commit( 'setApiPartner', partner );
        },
        setApiMockData( { commit }, value ) {
            commit( 'setApiMockData', value );
        },
        setLoading( { commit }, loading = true ) {
            commit ( 'setLoading', loading );
        },
        setHamburgerMenuVisible ( { commit }, value ) {
            commit ( 'setHamburgerMenuVisible', value );
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            return {
                language: state[STORE_NAME].language,
                locale: state[STORE_NAME].locale,
                api: {
                    mockData: state[STORE_NAME].api.mockData
                },
            };
        },
        getDataToLoad: ( data ) => {
            try {
                const schema = new Schema( {
                    language: {
                        type: String,
                        required: true,
                    },
                    locale: {
                        type: String,
                        required: true,
                    },
                    api: {
                        mockData: {
                            type: 'boolean',
                            required: true
                        }
                    },
                } );

                if ( !data || schema.validate( data ).length ) {
                    throw( new Error( 'Invalid schema for appState store' + JSON.stringify( schema.validate( data ) ) ) );
                }
                data.api.mockData && alert ( 'Using mock data' );

                return data;
            } catch ( e ) {
                LogService.error( `Vuex state cannot be loaded - AppState: ${ e.message }` );
                return null;
            }
        }
    }
};
