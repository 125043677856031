export default {
    TYPES: {
        roomOnly: 'ROOM ONLY',
        bedAndBreakfast: 'BED AND BREAKFAST',
        halfBoard: 'HALF BOARD',
        allInclusive: 'ALL INCLUSIVE',
        fullBoard: 'FULL BOARD',
        breakfastTwoGuests: 'Breakfast for two guests',
        buffetBreakfast: 'BUFFET BREAKFAST',
        selfCatering: 'SELF CATERING',
        dinnerIncluded: 'DINNER INCLUDED',
        continentalBreakfast: 'CONTINENTAL BREAKFAST',
        breakfastOneGuest: 'Breakfast for one guest',
        americanBreakfast: 'AMERICAN BREAKFAST',
        englishBreakfast: 'ENGLISH BREAKFAST',
        lightBreakfast: 'LIGHT BREAKFAST',
        lunchIncluded: 'LUNCH INCLUDED'
    },
    NO_MEAL_PLAN_TYPES: [
        'roomOnly'
    ]
};