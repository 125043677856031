export default {
    FLOWS: {
        DYNAMIC: 'DYNAMIC',
        HOTELS_PRE_ALLOCATED: 'HOTELS_PRE_ALLOCATED',
        HOTELS_OPEN_INVENTORY: 'HOTELS_OPEN_INVENTORY',
        PACKAGES: 'PACKAGES',
        EXPERIENCES: 'EXPERIENCES'
    },
    URL_OFFER_TYPES: {
        experiences: 'EXPERIENCES'
    },
    OFFER_TYPES_TO_PACKAGES: {
        experiences: 'PRE_BUNDLE_EXPERIENCE',
        packages: 'PRE_BUNDLE_PACKAGE',
        hotels: 'HOTEL_ONLY'
    },
    GIFT_CARD_OFFER_TYPES: {
        preAllocated: 'HOTEL_ONLY_PRE_ALLOCATED',
        openInventory: 'HOTEL_ONLY_OPEN_INVENTORY'
    }
};
