import apiConstants from '@core/utils/constants/api';
import { createApiService, fetchAPI } from '@core/api/_utils';

let api = {
    tokenStatus: {
        get( uid ) {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/token/${uid}/status` );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}