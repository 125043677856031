import numeral from 'numeral';
import numberConstants from '@core/utils/constants/number';

export function format( value, format = numberConstants.FORMAT.DEFAULT  ) {
    return numeral( value ).format( format );
}

export function divide( dividend, divisor, format = numberConstants.FORMAT.PERCENT ) {
    return numeral( dividend ).divide( divisor ).format( format );
}

export function isNumber ( value ) {
    return !Number.isNaN( value );
}
