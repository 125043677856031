import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { isCity as cityObjectUtilsIsCity } from '@core/utils/cityObjectUtils';
import ApiCancelService from '@core/services/ApiCancelService';
import asyncParallel from '@core/utils/asyncParallel';
import {
    buildSearchQueryObject as searchUtilsBuildSearchQueryObject
} from '@tenant/app/utils/searchUtils';

const STORE_NAME = 'searchEvents';

const filterObj = {
    city:               { },
    keyword:            '',
};
const EVENTS_LIMIT = 15;

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        filter: { ...filterObj },
        requestInProgress: 0,
        results: {
            attractions: [],
            events: [],
            venues: []
        },
        resultsFilter: { ...filterObj }
    },
    getters: {
        isEmptyKeywordFilter: state => {
            return !state.filter.keyword.trim( );
        },
        isEmptyFilter: ( state, getters ) => {
            return getters.isEmptyKeywordFilter && !cityObjectUtilsIsCity( state.filter.city );
        },
        isOnlyKeyword: state => {
            const { keyword, city } = state.filter;

            return keyword && isEmpty( city );
        },
        currentQueryObject: state => {
            const { keyword, city } = state.filter;

            return searchUtilsBuildSearchQueryObject( {
                keyword,
                city
            } );
        },
        filter: state => {
            let ret = { },
                { filter } = state;

            if ( cityObjectUtilsIsCity( filter.city ) ) {
                ret.city = filter.city;
            }
            !( filter.keyword && filter.keyword )                   || ( ret.keyword = filter.keyword );

            return ret;
        },
        isEqualFilterResultFilter: state => {
            return isEqual( state.filter, state.resultsFilter );
        },
        loading: ( state, getters ) => !!state.requestInProgress || ( !getters.isEqualFilterResultFilter && !getters.isEmptyFilter ),
        hasResults: state => {
            return state.results.attractions.length || state.results.events.length || state.results.venues.length;
        }
    },
    mutations: {
        clearFilter ( state ) {
            state.filter = { ...filterObj };
        },
        setFilterCity ( state, city ) {
            state.filter.city = city;
        },
        setFilterKeyword ( state, keyword ) {
            state.filter.keyword = keyword;
        },
        updateResultFilter( state ) {
            state.resultsFilter = cloneDeep( state.filter );
        },
        incrementRequestInProgress( state ) {
            state.requestInProgress = state.requestInProgress + 1;
        },
        decrementRequestInProgress( state ) {
            state.requestInProgress = state.requestInProgress - 1;
        },
        updateResults ( state, results ) {
            state.results.attractions = results.attractions ? [ ...results.attractions ] : [];
            state.results.events = results.events ? [ ...results.events ] : [];
            state.results.venues = results.venues ? [ ...results.venues ] : [];
        },
        clearResults( state ) {
            state.results.attractions = [ ];
            state.results.events = [ ];
            state.results.venues = [ ];
        },
        clear( state ) {
            state.results.attractions = [ ];
            state.results.events = [ ];
            state.results.venues = [ ];
            state.filter = { ...filterObj };
            state.resultsFilter = { ...filterObj };
        },
    },
    actions: {
        update( { commit, dispatch }, data ) {
            if ( data.hasOwnProperty( 'city' ) ) {
                if ( cityObjectUtilsIsCity ( data.city ) ) {
                    commit( 'setFilterCity', data.city );
                } else {
                    commit( 'setFilterCity', { } );
                }
            }

            !( data.hasOwnProperty( 'keyword' ) )   || commit( 'setFilterKeyword', data.keyword );
            dispatch( 'search' );
        },
        clear( { commit } ) {
            commit( 'clear' );
        },
        search:( { dispatch } ) => {
            dispatch( 'searchDebounced' );
        },
        searchDebounced: debounce( ( { dispatch } ) => {
            dispatch( 'doSearch' );
        }, 500 ),
        async doSearch( { state, dispatch, commit, getters } ) {
            if ( getters.isEqualFilterResultFilter ) {
                return;
            }
            let attractions, venues, events;
            commit( 'updateResultFilter' );
            if ( getters.isEmptyFilter ) {
                commit( 'clearResults' );
                return;
            }
            const apiCancelKey = 'search.products';

            ApiCancelService.clear( apiCancelKey );
            const inProgressFilter = cloneDeep( state.filter );
            const params = { search: { ...inProgressFilter, limit: EVENTS_LIMIT }, requestConfig: { cancelToken: ApiCancelService.getToken( apiCancelKey ) } };

            commit( 'incrementRequestInProgress' );
            // const events = await dispatch( 'events/searchList', params, {  root: true } );
            await asyncParallel( [
                async ( ) => { attractions = await dispatch( 'attractions/searchList', params, {  root: true } ); },
                async ( ) => { events = await dispatch( 'events/searchList', params, {  root: true } ); },
                async ( ) => { venues = await dispatch( 'venues/searchList', params, {  root: true } ); },
            ], 3 );
            commit( 'decrementRequestInProgress' );

            // ignore responses if the filter change in the meantime and axios cancel did not work
            if ( isEqual( inProgressFilter, state.filter ) ) {
                commit( 'updateResults', { attractions, venues, events } );
            }
        }
    }
};
