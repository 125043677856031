import IconChevronLeft from '@core/shared/components/icons/IconChevronLeft.vue';
import IconChevronRight from '@core/shared/components/icons/IconChevronRight.vue';
import IconClose from '@core/shared/components/icons/IconClose.vue';

export default {
    prev: {
        component: IconChevronLeft,
        name: 'prev'
    },
    next: {
        component: IconChevronRight,
        name: 'next'
    },
    close: {
        component: IconClose,
        name: 'close'
    },
    chevronLeft: {
        component: IconChevronLeft,
        name: 'chevronLeft'
    },
    chevronRight: {
        component: IconChevronRight,
        name: 'chevronRight'
    },
};
