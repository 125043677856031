export default {
    CART: {
        FLOWS: {
            DYNAMIC: 'DYNAMIC',
            HOTELS_PRE_ALLOCATED: 'HOTELS_PRE_ALLOCATED',
            HOTELS_OPEN_INVENTORY: 'HOTELS_OPEN_INVENTORY',
            PACKAGES: 'PRE_BUNDLE_PACKAGE',
            EXPERIENCES: 'PRE_BUNDLE_EXPERIENCE'
        }
    }
};
