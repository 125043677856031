import normalizeDiscount from './normalize/discount';

export function normalizeOffer( data, allInclusivePricing ) {
    const marketBasePrice = data.basePrice;

    const ret = {
        id: data.id || null,
        ticketTypeId: data.ticketTypeId || null,
        name: data.name || '',
        currency: data.currency || null,
        loyaltyCurrency: data.loyaltyCurrency || null,
        basePrice: data.basePrice || 0,
        totalPrice: data.totalPrice || 0,
        feePrice: data.totalPrice - data.basePrice,
        pointsEarned: data.pointsEarned || 0,
        earnRate: data.earnRate || 0,
        redeemPoints: data.redeemPoints || 0,
        limitMin: data.limitMin || 0,
        limitMax: data.limitMax || 0,
        listingNotes: data.listingNotes || null,
        /**
         * total price without discount
         */
        marketTotalPrice: data.marketTotalPrice || 0,
        /**
         * basePrice without discount
         *
         * For the moment there are no discounts on the basePrice
         */
        marketBasePrice: marketBasePrice || 0,
        /**
         * feePrice without discount
         */
        marketFeePrice: data.marketTotalPrice - marketBasePrice,
        discounts: data.discounts?.map( normalizeDiscount ),
        /**
         * show totalPrice or basePrice
         */
        allInclusivePricing
    };

    return ret;
}
