export default {
    CURRENCY_TYPES: {
        MONEY: 'money',
        POINTS: 'points'
    },
    TYPES: {
        EARN:   'earn',
        BOTH:   'both',
        REDEEM:  'redeem',
        NONE:   'none'
    },
    REDEEM: {
        MIN_POINTS: 1000,
    }
};
