import i18next from 'i18next';
import { getLang as documentUtilsGetLang } from '@core/utils/documentUtils';
import {
    isSameDay as dateUtilsIsSameDay,
} from '@tenant/app/utils/dateUtils';
import datePeriod from './datePeriod';

export default function eventPeriod( startDate, endDate, hasStartTime = true ) {
    if( !startDate ) {
        return i18next.t( '_common:terms.dateAndTimeToBeDetermined' );
    }

    const multiDayEvents = endDate && !dateUtilsIsSameDay( startDate, endDate );

    return datePeriod(
        startDate,
        endDate,
        { full: 'eee • MMM d, yyyy', period: 'eee • MMM d', periodFull: 'eee • MMM d, yyyy', time: 'p' },
        documentUtilsGetLang( ),
        !multiDayEvents && hasStartTime
    );
}