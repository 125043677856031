/**
 * MODIFY THE KEY WITH EXTRA CAREFULL
 */

export default {
    KEYS: {
        STORE: 'gstStore',
        PAGES: {
            EVENT: 'gst.pages.event'
        }
    }
};