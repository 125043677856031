import cloneDeep from 'lodash/cloneDeep';

export function create( hotel ) {
    const accommodation = hotel && hotel.rooms ? hotel.rooms[ 0 ].accommodation : { } ;
    const guests = {
        adultsCount:    0,
        childrenCount:  0,
        childAges:      [ ],
    };

    const a = {
        hotel: hotel,
        quantity: {
            roomsCount: accommodation.roomsCount || 0,
            guests: accommodation.guests || guests,
            nights: accommodation.nights || 0,
        },
        startDateTime: accommodation.startDateTime || new Date( ),
        endDateTime: accommodation.endDateTime || new Date( ),
        totals: {
            basePrice: 0,
            basePriceWithoutDiscount: 0,
            discountedAmount: 0,
            includesTaxesAndFees: false,
            totalPrice: 0
        },
        /**
         * Functions
         */
        setSelectedRoom( room ) {
            this.hotel.selectedRoom = room;
            this.updateTotal( );
        },
        setSelectedRoomWithRate(  selectedRate ) {
            this.hotel.selectedRoom.selectedRate = selectedRate;
            this.updateTotal( );
        },
        updateTotal( ) {
            if ( this.hasValue( ) && this.hotel.selectedRoom ) {
                const roomBasePrice = this.hotel.selectedRoom.selectedRate.totals.basePrice;
                const roomTotalPrice = this.hotel.selectedRoom.selectedRate.totals.totalPrice;
                const roomMarketPrice = this.hotel.selectedRoom.selectedRate.marketPrice;
                const packageOffer = this.getPackageOffer( );
                const offer = this.getOffer( );

                if ( offer ) {
                    this.totals.basePrice = offer.basePrice;
                    this.totals.basePriceWithoutDiscount = offer.basePrice;
                    this.totals.totalPrice = roomTotalPrice;
                } else if ( packageOffer ) {
                    this.totals.basePrice = packageOffer.basePrice;
                    this.totals.basePriceWithoutDiscount = packageOffer.basePrice;
                    this.totals.totalPrice = packageOffer.basePrice;
                } else {
                    this.totals.basePrice = roomBasePrice;
                    this.totals.basePriceWithoutDiscount = roomMarketPrice || roomBasePrice;
                    this.totals.discountedAmount = Math.ceil( this.totals.basePriceWithoutDiscount ) - Math.ceil( this.totals.basePrice );
                    this.totals.totalPrice = roomTotalPrice;
                }

                this.totals.includesTaxesAndFees = this.hotel.includesTaxes;
            }
        },
        isPackageOffer( ) {
            return this.hasValue( ) && this.hotel.selectedRoom && this.hotel.selectedRoom.selectedRate.packageOffer && this.hotel.selectedRoom.selectedRate.packageOffer.id;
        },
        getPackageOffer( ) {
            if ( this.isPackageOffer( ) ) {
                return this.hotel.selectedRoom.selectedRate.packageOffer;
            }
            else false;
        },
        isOffer( ) {
            return this.hasValue( ) && this.hotel.selectedRoom && this.hotel.selectedRoom.selectedRate.offer && this.hotel.selectedRoom.selectedRate.offer.id;
        },
        getOffer( ) {
            if ( this.isOffer( ) ) {
                return this.hotel.selectedRoom.selectedRate.offer;
            }
            return null;
        },
        hasValue( ) {
            return this.hotel && this.hotel.id !== null;
        },
        _clone( ) {
            return cloneDeep( this );
        }
    };

    a.updateTotal( );

    return a;
}
