export default function ( { amount, value, type, appliedTo, limitMax, description, shortMessage, bottomBannerMessage, topBannerMessage  } ) {
    return {
        amount: amount || 0,
        value: value || 0,
        type,
        appliedTo,
        limitMax,
        description,
        messages: {
            short: shortMessage || '',
            topBanner: topBannerMessage || '',
            bottomBanner: bottomBannerMessage || ''
        }
    };
}