<template>
    <v-app>
        <component :is="layout || 'div'">
            <router-view v-if="isAppReady" />
            <v-overlay :value="showLoadingOverlay" color="white" opacity="0.8" z-index="5002">
                <DataLoading size="40" />
            </v-overlay>
        </component>
        <div id="modals_container"></div>
        <NotificationContainer />
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import debounce from 'lodash/debounce';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import NotificationContainer from '@core/shared/components/notifications/NotificationContainer';
    import viewportConstants from '@core/utils/constants/viewport';
    import { setMetaViewport as viewportUtilsSetMetaViewport } from '@core/utils/viewportUtils';
    import LocalStorageService from '@tenant/app/services/LocalStorageService';
    import { saveReferrerUrl } from '@tenant/app/utils/tenantUrlslUtils';
    import { initDateFnsLocales } from '@tenant/app/utils/dateUtils';
    import BlankLayout from '@tenant/app/layouts/Blank';
    import NewLayout from '@tenant/app/layouts/NewLayout';
    import HeaderOnlyLayout from '@tenant/app/layouts/HeaderOnly';

    /* eslint-disable vue/component-definition-name-casing */
    Vue.component( 'blank-layout', BlankLayout );
    /* eslint-enable vue/component-definition-name-casing */
    Vue.component( 'NewLayout', NewLayout );
    Vue.component( 'HeaderOnlyLayout', HeaderOnlyLayout );


    const STORE_SEARCH_STATE = 'searchState';
    const DEFAULT_LAYOUT = 'new';

    export default {
        name: 'App',
        components: {
            NotificationContainer,
            DataLoading
        },
        data() {
            return {
                isTokenStatusReady: false
            };
        },
        computed: {
            ...mapState( {
                initialSearch: state => state[STORE_SEARCH_STATE].initial,
                loadingOverlay: state => state['appState'].loading,
            } ),
            layout( ) {
                const layout = this.$route.meta.layout || DEFAULT_LAYOUT;

                if ( layout.toLocaleLowerCase( ) === 'blank' ) {
                    return 'blank-layout';
                } else if ( layout === 'headerOnly' ) {
                    return 'HeaderOnlyLayout';
                } else
                    return 'NewLayout';
            },
            ...mapGetters( {
                isCartSubmitting: `cart/isCartSubmitting`,
            } ),
            isAppReady() {
                return this.isTokenStatusReady;
            },
            showLoadingOverlay() {
                return this.loadingOverlay || !this.isAppReady;
            }
        },
        methods: {
            ...mapActions( {
                instantDeleteAllCartsIfExpired:     'cart/deleteAllIfExpired',
                instantDeleteCurrentCart:           'cart/instantDeleteCurrentCart',
                getUserCurrentLocation:             'user/location/getCurrentLocation',
                getAppStateTokenStatus:                       'appState/getTokenStatus',
                getTenantConfig:                    'appTenant/getConfig',
                updateLangList: 'languages/updateLangList'
            } ),
            showModal( to ) {
                const modalConfig = to.meta.modalConfig;
                const platformModalConfig = this.$vuetify.breakpoint.mdAndUp ? modalConfig.configDesktop : modalConfig.configMobile;
                const defaultModalConfig = {
                    'no-click-animation': true,
                    persistent: true,
                    scrollable: true,
                    'hide-overlay': true,
                    'retain-focus': false,
                };
                const componentProps = { ...modalConfig.props, ...to.params };
                const config = { ...defaultModalConfig, ...modalConfig.config, ...platformModalConfig };

                if( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$modal.show( modalConfig.component, componentProps, config );
                } else {
                    this.$modal.showBottom( modalConfig.component, componentProps, config );
                }

            },
            applyBeforeEachOnRouter( ) {
                const router = this.$router;
                const filterEachRoute = async ( to, from, next ) => {
                    // reset the available languages for the language selector
                    if ( to.name !== from.name && !to.meta.suppressLangReset ) {
                        this.updateLangList( [] );
                    }
                    if ( to.meta.defaultDocumentTitle ) {
                        document.title = this.$t( 'main:viewsTitle.theHome' );
                    }
                    if ( from.name === 'cartCheckout' && to.name !== 'cartCheckout' ) {
                        const doDelete = () => {
                            this.instantDeleteCurrentCart( );
                        };
                        // Avoid cart deletion while submit process is running
                        if ( this.isCartSubmitting ) {
                            const unwatch = this.$watch( 'isSubmitting',
                                                         ( value ) => {
                                                             if ( !value && this.cart.id ) {
                                                                 doDelete();
                                                             }
                                                             unwatch();
                                                         } );
                        } else {
                            doDelete();
                        }
                    }

                    if ( to.meta.type === 'modal' ) {
                        this.showModal( to );
                    } else {
                        next( );
                    }
                };

                router.beforeEach( filterEachRoute );
            },
            /** Calculate relative vh based on innerHeight
             * (necessary in order to correctly display elements on mobile with and without addressbar)
             * **/
            setRelativeVh: debounce( function ( ) {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty( '--vh', `${vh}px` );
            }, 50 ),
            saveUserToken( ) {
                const { _uid } = this.$route.query;
                
                if ( _uid ) {
                    LocalStorageService.userToken.set( _uid );
                }
                    
            },
            async getTokenStatus( ) {
                const _uid = LocalStorageService.userToken.get( );

                if ( _uid ) {
                    await this.getAppStateTokenStatus( _uid );
                }
                this.isTokenStatusReady = true;
            }
        },
        async created( ) {
            this.instantDeleteAllCartsIfExpired( );

            saveReferrerUrl();

            await this.getTenantConfig( );
            await initDateFnsLocales( this.$i18n.i18next.languages );

            this.$router.onReady( () => {
                this.applyBeforeEachOnRouter( );
                this.saveUserToken( );
                this.getTokenStatus( );
            } );

            if ( this.$vuetify.breakpoint.smAndDown && this.$device.hasIos ) {
                viewportUtilsSetMetaViewport( viewportConstants.IOS );
            }
            this.setRelativeVh( );
            window.addEventListener( 'resize', this.setRelativeVh );
        },
        beforeDestroy() {
            window.removeEventListener( 'resize', this.setRelativeVh );
        }
    };
</script>

<style lang="scss">
.v-application--wrap {
    min-height: calc( var( --vh, 1vh ) * 100 ) !important;
}
</style>
