import loyalty from '@core/utils/constants/loyalty';
import normalizeDiscount from '@core/data/response/normalize/discount';
import { normalizeEventPurchaseFlow } from './normalize/eventPurchaseFlow';

export function normalizeTenantConfig( data ) {
    return {
        transactableType: normalizeEventPurchaseFlow( data.transactableType ),
        authenticationType: data.authenticationType,
        loyalty: data.loyalty ? normalizeLoyalty( data.loyalty ) : {},
        discounts: Array.isArray( data.discounts ) ? data.discounts.map( normalizeDiscount ) : null,
        minimumRedemptionPoints: data.minimumRedemptionPoints || loyalty.REDEEM.MIN_POINTS
    };
}

function normalizeLoyalty( data ) {
    return {
        programName: data.programName || null,
        /**
         * Value type receive are UPPERCASE.
         * Those keys are inside dictionary with lowercase
         */
        type: data.type?.toLowerCase( ) || loyalty.TYPES.NONE
    };
}
