<template>
    <footer class="gst-footer">
        <div class="gst-footer__column d-flex">
            <a :href="domainUrl">
                <img src="@tenant/app/assets/logo_ticketmaster.png" alt="ticketmaster" />
            </a>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="text-center" v-html="$t('terms', { url: policyUrl })"></div>
        </div>
        <div class="gst-footer__column d-flex">
            <ManageCookiesButton class="gst-footer__manage-cookies-button" />
            <div class="gst-footer__country d-flex justify-space-between">
                <span>{{ $t('copyright', { year: currentYear }) }}</span>
                <span>
                    <img src="@tenant/app/assets/flag_us.png" :alt="$t('us')" />
                    {{ $t('us') }}
                </span>
            </div>
        </div>
    </footer>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';
    import ManageCookiesButton from '@tenant/app/components/misc/ManageCookiesButton';

    export default {
        name: 'TheFooter',
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'views.home.theFooter'
        },
        components: {
            ManageCookiesButton
        },
        computed: {
            domainUrl() {
                return tenantUrlsConstants.home;
            },
            policyUrl() {
                return this.$router.resolve( { name: 'policy' } ).route.path;
            },
            currentYear() {
                return new Date().getFullYear();
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-footer {
        display: flex;
        width: 100%;
        line-height: 19px;
        padding: 50px 56px;
        background: theme-color( 'new-quaternary' );
        color: theme-color( 'white' );
        font-size: 16px;
        font-weight: 400;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: theme-spacing( 8 );

        .gst-footer__column {
            flex-direction: column;
            align-items: center;
            gap: theme-spacing( 8 );
        }

        .gst-footer__country {
            font-size: font-size( 's' );
            gap: theme-spacing( 4 );
        }

        .gst-footer__manage-cookies-button#ot-sdk-btn.ot-sdk-show-settings {
            line-height: 19px;
            border: none;
            color: theme-color( 'white' );
            font-size: font-size( 's' );
            text-decoration: underline;
        }

        .gst-footer__manage-cookies-button#ot-sdk-btn.ot-sdk-show-settings:hover {
            background-color: theme-color( 'transparent' );
        }

        ::v-deep a {
            color: theme-color( 'new-secondary' );
        }

        @include desktop-only {
            padding: 100px 56px;
            flex-direction: row;
            justify-content: space-between;

            .gst-footer__column {
                flex-direction: row;
                align-items: center;
                gap: theme-spacing( 8 );
            }

            .gst-footer__country {
                gap: theme-spacing( 8 );
            }
        }
    }
</style>