import { LogService } from '@core/services';
import storeConstants from '@core/utils/constants/store';
import { isStoreOutDated as storeUtilsIsStoreOutDated } from '@core/utils/storeUtils';

const REFRESH_TIME = storeConstants.REFRESH_TIME;

const STORE_NAME = 'languages';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        list: [],
        eventLangs: [],
        legacyEventId: null,
        timestamp: 0
    },
    mutations: {
        setLangList( state, data ) {
            state.list = data;
        },
        setEventLang( state, data ) {
            state.eventLangs = data;
            state.timestamp = new Date( ).getTime( );
        },
        setLegacyEventId( state, data ) {
            state.legacyEventId = data;
        }
    },
    getters: {
        getAvailableLangs: state => {
            return state.list;
        }
    },
    actions: {
        updateLangList: ( { commit }, value ) => {
            commit( 'setLangList', value );
        },
        async updateLanguagesForEvent( { commit, state }, legacyEventId ) {
            const needsRefresh = storeUtilsIsStoreOutDated( state.timestamp, REFRESH_TIME );
            if ( state.legacyEventId === legacyEventId && !needsRefresh ) {
                commit( 'setLangList', state.eventLangs );
            } else {
                try {
                    const content = await this._vm.$prismic.client.getByUID(
                        'event-legal-info-checkout',
                        legacyEventId.toLocaleLowerCase( )
                    );
                    if ( content ) {
                        const alternativeLangs = content.alternate_languages.map( item => item.lang );
                        const langs = [
                            content.lang,
                            ...alternativeLangs
                        ];
                        commit( 'setLangList', langs );
                        commit( 'setEventLang', langs );
                        commit( 'setLegacyEventId', legacyEventId );
                    }
                } catch( e ) {
                    LogService.log( e.message );
                }
            }
        }
    }
};
