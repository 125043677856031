import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import LogService from '@core/services/LogService';
import ApiCancelService from '@core/services/ApiCancelService';
import searchHotelReservationConstants from '@tenant/app/utils/constants/searchHotelReservation';
import apiHotelService from '@tenant/app/api/apiHotelService';
import HotelReservationModel from '@tenant/app/models/HotelReservationModel';
import LocalStorageService from '@tenant/app/services/LocalStorageService';

const STORE_NAME = 'hotels';
import Guests from '@tenants/ticketmaster/app/models/GuestsModel';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        loading:                    false,
        loadingCounter              : 0,
        list:                       [ ],
        resultsNotFound:            false, //No results are found for the search area
        allItemsLoaded:             false,
        timestamp:                  0,
        limit:                      searchHotelReservationConstants.DEFAULT.limit,
        realOffset:                     0,
        initialLoad:                true,
        focusedHotel: null,
        productId: null, // used for pre-allocated hotel rooms
        eventId: null, // used for dynamic to get hotels for event
        offerType: null, // used for hotel only
        filters: {
            location: {
                latitude:                   0,
                longitude:                  0,
                countryCode:                null
            },
            range:                      searchHotelReservationConstants.DEFAULT.range,
            startDateTime:              null,
            endDateTime:                null,
            guests:                     new Guests( ),
            roomsCount:                 1
        }
    },
    getters: {
        getLocation: state => state.filters.location
    },
    mutations: {
        [ mutationTypes.START_LOADING ] ( state ) {
            state.loadingCounter = state.loadingCounter + 1;
            state.loading = !!state.loadingCounter;
        },
        [ mutationTypes.STOP_LOADING ] ( state ) {
            state.loadingCounter = state.loadingCounter > 0 ? state.loadingCounter - 1 : 0;
            state.loading = !!state.loadingCounter;
        },
        [ mutationTypes.APPEND_TO_LIST ] ( state, { data } ) {
            state.timestamp = new Date( ).getTime( );
            state.list = [
                ...state.list,
                ...data.map( item => {
                    const model = new HotelReservationModel( ).transform( item, !!state.productId );
                    model.selectedRoom = model.rooms[ 0 ];

                    return model;
                } )
            ];
        },
        setLimit( state, limit ) {
            state.limit = limit;
        },
        setRealOffset ( state, value ) {
            state.realOffset = value;
        },
        setInitialLoad ( state, value ) {
            state.initialLoad = value;
        },
        setResultsNotFound( state, value ) {
            state.resultsNotFound = value;
        },
        [ mutationTypes.SET_ALL_ITEMS_LOADED ] ( state, value ) {
            state.allItemsLoaded = value;
        },
        [ mutationTypes.UPDATE_FILTERS ] ( state, filters ) {
            state.filters = { ...filters };
        },
        [ mutationTypes.CLEAR_LIST ] ( state ) {
            state.timestamp = null;
            state.list = [ ];
            state.allItemsLoaded = false;
            state.resultsNotFound = false;
        },
        [ mutationTypes.CLEAR ]( state ) {
            state.loading = false;
            state.loadingCounter = 0;
            state.list = [ ];
            state.resultsNotFound = false;
            state.allItemsLoaded = false,
            state.timestamp =          0,
            state.limit =           searchHotelReservationConstants.DEFAULT.limit,
            state.realOffset =          0,
            state.filters = {
                location: {
                    latitude:           0,
                    longitude:          0,
                    countryCode:        null
                },
                range:              searchHotelReservationConstants.DEFAULT.range,
                startDateTime:      null,
                endDateTime:        null,
                guests:             new Guests( ),
                roomsCount:         1
            };
        },
        selectRoom( state, { id, value } ) {
            const index = state.list.findIndex( item => item.id === id );

            if ( index !== -1 ) {
                Vue.set( state.list, index, {
                    ...state.list[ index ],
                    selectedRoom: value
                } );
            }
        },
        setFocusedHotel( state, hotel ) {
            state.focusedHotel  = hotel;
        },
        setProductId( state, productId ) {
            state.productId = productId;
        },
        setEventId( state, data ) {
            state.eventId = data;
        },
        setOfferType( state, data ) {
            state.offerType = data;
        }
    },
    actions: {
        getByEventId: async ( { state, commit }, { eventId, offerType } = { }, ) => {
            commit( mutationTypes.START_LOADING );
            commit( mutationTypes.CLEAR_LIST );

            const api = await apiHotelService( '' );
            const response = await api.hotels.getOffersByEventId( { eventId, offerType } );

            if ( response.success ) {
                const listFiltered = response.data.list.reduce( ( acc, item ) => {
                    const hasRooms = !!( item.guestRooms && item.guestRooms.length );
                    const hasRoomRates = !!( hasRooms && item.guestRooms.find( item => item.roomRates && item.roomRates.length ) );

                    if ( !hasRooms ) {
                        LogService.warn( 'hotel without rooms', item );
                    } else if ( hasRooms && !hasRoomRates ) {
                        LogService.warn( 'hotel without room rates', item );
                    } else {
                        acc = [
                            ...acc,
                            item
                        ];
                    }

                    return acc;
                }, [] );
                // apply slice for pre-allocated inventory to show only first 'limit' number of results
                commit( mutationTypes.APPEND_TO_LIST, { data: listFiltered.slice( 0, state.limit ) } );
            } else {
                LogService.debug( 'problem loading hotels by event ' + eventId );
            }

            commit( mutationTypes.STOP_LOADING );
            return response;
        },
        loadPage: async ( { dispatch, state, commit }, { refresh = false, filters = { }, limit = searchHotelReservationConstants.DEFAULT.limit } ) => {
            let response;
            if ( state.productId && state.offerType ) {
                // clear list and update filters even for pre-allocated offers
                if ( refresh || !isEqual( { ...filters }, { ...state.filters } ) ) {
                    commit( mutationTypes.CLEAR_LIST );
                    commit( mutationTypes.UPDATE_FILTERS, filters );
                    commit( 'setRealOffset', 0 );
                    commit( 'setLimit', limit );
                }
                response = await dispatch( 'getByEventId', { eventId: state.productId, offerType: state.offerType } );
                if ( response.success && !state.list.length ) {
                    // dispatch action to change to open inventory flow
                    commit( 'setOfferType', null );
                    response = await dispatch( 'get', { refresh, filters, limit } );
                }
                return response;
            } else {
                response = await dispatch( 'get', { refresh, filters, limit } );
            }
            return response;
        },
        get: async ( { dispatch, commit, state }, { refresh = false, filters = { }, limit = searchHotelReservationConstants.DEFAULT.limit } ) => {
            commit( mutationTypes.START_LOADING );
            const api = await apiHotelService( '' );
            const apiCancelKey = 'hotels.get';
            let cancelToken;

            ApiCancelService.clear( apiCancelKey );
            cancelToken = ApiCancelService.getToken( apiCancelKey );
            if ( refresh || !isEqual( { ...filters }, { ...state.filters } ) ) {
                commit( mutationTypes.CLEAR_LIST );
                commit( mutationTypes.UPDATE_FILTERS, filters );
                commit( 'setRealOffset', 0 );
                commit( 'setLimit', limit );
            }
            let response;
            if ( state.eventId ) {
                response = await api.hotels.getByProduct( state.eventId,
                    {
                        offset:         state.realOffset || state.list.length,
                        limit:          state.limit
                    },
                    {
                        startDate:      filters.startDateTime,
                        endDate:        filters.endDateTime,
                        longitude:      filters.location.longitude,
                        latitude:       filters.location.latitude,
                        countryCode:    filters.location.countryCode,
                        guests:         filters.guests,
                        roomsCount:     filters.roomsCount,
                        range:          filters.range
                    },
                    {
                        cancelToken
                    }
                );
            } else {
                response = await api.hotels.get(
                    {
                        offset:         state.realOffset || state.list.length,
                        limit:          state.limit
                    },
                    {
                        startDate:      filters.startDateTime,
                        endDate:        filters.endDateTime,
                        longitude:      filters.location.longitude,
                        latitude:       filters.location.latitude,
                        countryCode:    filters.location.countryCode,
                        guests:         filters.guests,
                        roomsCount:     filters.roomsCount,
                        range:          filters.range
                    },
                    {
                        cancelToken
                    }
                );
            }

            if ( response.success ) {
                let list = response.data.items;

                const listFiltered = list.reduce( ( acc, item ) => {
                    const hasRooms = !!( item.guestRooms && item.guestRooms.length );
                    const hasRoomRates = !!( hasRooms && item.guestRooms.find( item => item.roomRates && item.roomRates.length ) );

                    if ( !hasRooms ) {
                        LogService.warn( 'hotel without rooms', item );
                    } else if ( hasRooms && !hasRoomRates ) {
                        LogService.warn( 'hotel without room rates', item );
                    } else {
                        acc = [
                            ...acc,
                            item
                        ];
                    }

                    return acc;
                }, [] );

                list = listFiltered;

                if ( list.length < state.limit ) {
                    commit( mutationTypes.SET_ALL_ITEMS_LOADED, true );
                }
                commit( mutationTypes.APPEND_TO_LIST, { data: list } );
                commit( mutationTypes.STOP_LOADING );
                commit( 'setRealOffset', response.data.offset );
                LocalStorageService.userRuleSet.set( response.data.userRuleSet || '' );
            } else {
                LogService.debug( 'problem loading hotels' );
                commit( mutationTypes.STOP_LOADING );
            }
            commit( 'setResultsNotFound', !state.list.length );
            dispatch( 'addHotelReservationState/map/setSearchCenter', { latitude: filters.location.latitude, longitude: filters.location.longitude }, { root: true } );

            return response;
        },
        loadOne: async ( { commit }, { filters = { }, id, dataProvider } ) => {
            commit( mutationTypes.START_LOADING );
            const api = await apiHotelService( '' );
            const response = await api.hotels.one(
                id,
                {
                    startDate:      filters.startDateTime,
                    endDate:        filters.endDateTime,
                    guests:         filters.guests,
                    roomsCount:     filters.roomsCount,
                },
                dataProvider
            );
            if ( response.success ) {
                commit( mutationTypes.STOP_LOADING );
                const model = new HotelReservationModel( ).transform( response.data );
                model.selectedRoom = model.rooms[ 0 ];

                return model;
            } else {
                LogService.debug( 'problem loading hotels' );
                commit( mutationTypes.STOP_LOADING );
                return null;
            }
        },
        selectRoom( { commit }, { id, value } ) {
            commit( 'selectRoom', { id, value } );
        },
        clear( { commit } ) {
            commit( mutationTypes.CLEAR );
        },
        clearList( { commit } ) {
            commit( mutationTypes.CLEAR_LIST );
            commit( 'setRealOffset', 0 );
            commit( 'setLimit', searchHotelReservationConstants.DEFAULT.limit );
        },
        focusHotel( { commit }, hotel ) {
            commit( 'setFocusedHotel', hotel );
        },
        setProductId( { commit }, productId ) {
            commit( 'setProductId', productId );
        },
        setEventId( { commit }, eventId ) {
            commit( 'setEventId', eventId );
        },
        setOfferType( { commit }, data ) {
            commit( 'setOfferType', data );
        },
        setInitialLoad( { commit }, value ) {
            commit( 'setInitialLoad', value );
        },
        setResultsNotFound( { commit }, value ) {
            commit( 'setResultsNotFound', value );
        }
    }
};
