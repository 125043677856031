export default {
    API_URLS: {
        LOCALHOST: 'https://ticketmaster-travel-exp-test.cdn.prismic.io/api/v2',
        TEST: 'https://ticketmaster-travel-exp-test.cdn.prismic.io/api/v2',
        UAT: 'https://ticketmaster-travel-exp-uat.cdn.prismic.io/api/v2',
        PRODUCTION: 'https://ticketmaster-travel-exp.cdn.prismic.io/api/v2',
    },
    PREVIEW_SCRIPT_URLS: {
        LOCALHOST: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=ticketmaster-travel-exp-test',
        TEST: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=ticketmaster-travel-exp-test',
        UAT: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=ticketmaster-travel-exp-uat',
        PRODUCTION: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=ticketmaster-travel-exp',
    }
};