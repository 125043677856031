export default function ( { name = '', standardNumBeds = '', bedTypeCode = ''  } ) {
    let ret = `${name}`;

    if ( standardNumBeds || bedTypeCode ) {
        ret = `${ret}`;
        if ( standardNumBeds ) {
            ret = standardNumBeds === 1 ? `${ret}` : `${standardNumBeds} x ${ret}`;
        }
        if ( bedTypeCode ) {
            ret = `${ret}, ${bedTypeCode}`;
        }
    }

    return ret;
}
