import isEmpty from 'lodash/isEmpty';
import {
    parseDateString as dateUtilsParseDateString
} from '@tenant/app/utils/dateUtils';
import { ResponsiveImageModel } from '@tenants/ticketmaster/app/models/ImageModel';
import AccommodationModel from './AccommodationModel';

export default class HotelReservationRoomModel {
    constructor( ) {
        this.id                     = '';
        this.name                   = '';
        this.bedTypeCode            = '';
        this.standardNumBeds        = 0;
        this.nonSmoking             = true;
        this.rates                  = [];
        this.selectedRate           = null;
        this.amenities              = [ ];
        this.images                 = [ ];

        return this;
    }

    transform( obj, accommodation ) {
        this.id                     = obj.roomCode;
        this.name                   = obj.roomType;
        this.bedTypeCode            = obj.bedTypeCode;
        this.standardNumBeds        = obj.standardNumBeds;
        this.nonSmoking             = obj.nonSmoking;
        this.rates                  = obj.roomRates.map( rate => new RoomRate( rate ) );
        this.selectedRate           = this.rates[0];
        this.amenities              = obj.amenities ? obj.amenities.map( item => new RoomAmenity( item ) ) : [ ] ;
        this.images                 = obj.images.map( item => new ResponsiveImageModel(  ).transform( item ) );
        this.accommodation          = new AccommodationModel( ).transform( accommodation );
        return this;


    }
}

class RoomAmenity {
    constructor( obj ) {
        this.name                   = obj.name;
        this.quantity               = obj.quantity;
        this.price                  = obj.amount || 0;
        this.currency               = obj.currency;
        this.included               = !!obj.included;
    }
}

class RoomRate {
    constructor( obj ) {
        this.id                     = obj.rateId;
        this.currency               = obj.currency;
        this.inventory              = obj.inventory;
        this.totalPrice             = obj.totalPrice ? obj.totalPrice : undefined;
        this.netPrice               = obj.netPrice ? obj.netPrice : this.totalPrice;
        this.mealPlan               = obj.mealPlan;
        this.fees = obj.fees.map( item => new RoomRateFee( item ) );
        this.marketPrice = obj.marketPrice || null,
        this.discountedAmount = obj.discountedAmount || null,
        this.type = obj.rateType || null;
        this.paymentType = obj.paymentType || null;
        this.cancellationPolicies     = obj.cancellationPolicies ? obj.cancellationPolicies.map( item => new RoomRateCancellationPolicy( item ) ) : null;
        this.totals                 = {
            basePrice: this.totalPrice || 0,
            afterTaxPrice: this.netPrice || 0,
            totalPrice: this.totalPrice || 0
        };
        this.packageOffer = !isEmpty( obj.offer ) ? new RoomRatePackageOffer( obj.offer ) : null;
        this.offer = !isEmpty( obj.offer ) ? new RoomRateOffer( obj.offer ) : null;
        this.giftCard = !isEmpty( obj.giftCard ) ? new RoomRateGiftCard( obj.giftCard ) : null;
    }
}

class RoomRateFee {
    constructor( obj ) {
        this.dateRange = {
            startDate: obj.dateRange.startDate,
            endDate: obj.dateRange.endDate
        };
        this.fee = {
            name: obj.fee.name,
            type: obj.fee.type,
            typeIncluded: obj.fee.included,
            amount: obj.fee.amount,
            amountType: obj.fee.amountType,
            chargeType: obj.fee.chargeType,
            effectivePerson: obj.fee.effectivePerson
        };
    }
}

class RoomRateCancellationPolicy {
    constructor( obj ) {
        this.amount = obj.amount;
        this.fromAt = obj.from ? dateUtilsParseDateString( obj.from ) : null;
        this.percent = obj.percent;
        this.numberOfNights = obj.numberOfNights;
        this._originalFromAt = obj.from;
        this.fullCharge = obj.fullCharge;
        this.nonRefundable = obj.nonRefundable;
    }
}

class RoomRatePackageOffer {
    constructor( obj ) {
        this.basePrice      = obj.basePrice;
        this.id             = obj.id;
        this.ticketTypeId   = obj.ticketTypeId;
    }
}

class RoomRateOffer {
    constructor( obj ) {
        this.basePrice = obj.basePrice;
        this.id = obj.id;
        this.type = obj.type;
    }
}

class RoomRateGiftCard {
    constructor( obj ) {
        this.amount = obj.amount || 0;
        this.currency = obj.currency || null;
    }
}
