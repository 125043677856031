export default {
    DEFAULT: {
        LIMIT: 20,
        LOCATION: {
            RANGE: {
                DEFAULT: 50, //KM
                FALLBACK: 100 //KM
            }
        }
    }
};
