export default {
    TYPES: {
        ATTRACTION: 'attraction',
        EVENT: 'event',
        VENUE: 'venue'
    },
    TYPES_API: {
        ATTRACTION: 'ATTRACTION',
        EVENT: 'EVENT',
        VENUE: 'VENUE'
    }
};
