import { render, staticRenderFns } from "./NotificationContainer.vue?vue&type=template&id=6cdb4495&scoped=true&"
import script from "./NotificationContainer.vue?vue&type=script&lang=js&"
export * from "./NotificationContainer.vue?vue&type=script&lang=js&"
import style0 from "./NotificationContainer.vue?vue&type=style&index=0&id=6cdb4495&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdb4495",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VContainer,VFlex,VRow})
