import debounce from 'lodash/debounce';

export function setLang( value ) {
    document.documentElement.setAttribute( 'lang', value );
}

export function getLang( ) {
    return document.documentElement.getAttribute( 'lang' );
}

export function toggleBodyScroll( value ) {
    if ( value ) {
        const disableBodyScroll = debounce( () => {
            document.querySelector( 'body' ).classList.add( 'u-prevent-scroll' );
        }, 50 );
        disableBodyScroll();
    } else {
        document.querySelector( 'body' ).classList.remove( 'u-prevent-scroll' );
    }
}