export default {
    PROVIDERS: {
        MAPBOX: 'mapbox'
    },
    CONFIG: {
        MAPBOX: {
            apiKey: 'pk.eyJ1IjoiZ2xvYmFsc29sdXRpb25zIiwiYSI6ImNsZGV1ZHlnODBoNDAzdW5ldXE1NTd1YWcifQ._XfnKhk9r6NZC39Qd7Wp7A'
        }
    }
};
