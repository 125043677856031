import uuid from 'uuid-random';
import Cookies from 'js-cookie';
import { getSetting as getSettingStorageUtils, saveSetting as saveSettingStorageUtils } from '@core/utils/storageUtils';

const STORAGE_KEY = 'gst-session-id';

export function generateSessionId( ) {
    return uuid( ) + '-' + new Date( ).getTime( );
}

/**
 * This function will get the session storage from the localStorage
 * If not found it will create one with 365 days as expire and it will refresh that time 
 * when it is called.
 * 
 * Note: In the beginning that key was keep on cookies. We will copy that from cookies
 * and put in the localStorage so we will not loose that
 */
export function getSessionId( ) {
    const cookieValue = Cookies.get( STORAGE_KEY );
    const storageValue = getSettingStorageUtils( STORAGE_KEY )?.value;
    const finalValue = storageValue || cookieValue;
    const ttl = 365 * 24 * 60 * 60 * 1000; //365 days - time to life

    if ( cookieValue ) {
        Cookies.remove( STORAGE_KEY );
    }
    if ( finalValue ) {
        saveSettingStorageUtils( STORAGE_KEY, finalValue, ttl );
        return finalValue;
    } else {
        const sessionId = generateSessionId( );
        saveSettingStorageUtils( STORAGE_KEY, sessionId, ttl );

        return sessionId;
    }
}

export function hasSessionId( ) {
    return !!getSettingStorageUtils( STORAGE_KEY );
}