<template>
    <ul class="gst-navigation">
        <li
            v-for="(link, index) in links"
            :key="index"
            tabindex="0"
            @click="$emit( 'link-click' )"
            @keypress.enter="$emit( 'link-click' )">
            <router-link :to="link.to">
                <BaseIcon v-if="link.icon" :symbol-id="link.icon" class="gst-navigation__icon mr-2" />
                <span>{{ $t(link.name) }}</span>
            </router-link>
        </li>
    </ul>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import searchConstants from '@tenant/app/utils/constants/search';

    export default {
        name: 'TheNavigation',
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'layouts.theNavigation'
        },
        components: {
            BaseIcon
        },
        data( ) {
            return {
                links: [
                    {
                        name: 'search',
                        icon: 'icons--search',
                        to: {
                            name: 'home',
                            query: { searchType: searchConstants.TYPES.EVENTS },
                            hash: '#search-products'
                        }
                    },
                    {
                        name: 'latestDeals',
                        to: {
                            name: 'home',
                            hash: '#latest-deals'
                        }
                    },
                    {
                        name: 'help',
                        to: {
                            name: 'home',
                            hash: '#faq'
                        }
                    }
                ]
            };
        }
    };
</script>

<style lang="scss" scoped>
    .gst-navigation {
        padding: 0;
        list-style: none;
    }

    .gst-navigation__icon {
        vertical-align: middle;
    }
</style>