import currencyConstants from '@core/utils/constants/currency';
import { normalizeEvent } from '@core/data/response/normalizeEvent';
import normalizeHotel from './normalize/packageOfferHotel';

export function normalizePackageDetails( data ) {
    return {
        event: normalizeEvent( data.event ),
        hotel: normalizeHotel( data.hotel ),
        packageDescription: data.description || '',
        currency: data.currency || currencyConstants.DEFAULT,
        rateComments: data.rateComments || '',
        payLaterFee: data.payLaterFee || 0,
        totals: {
            totalPrice: data.totalPrice || 0,
            basePrice: data.basePrice || 0,
        }
    };
}
