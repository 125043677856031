import isEqual from 'lodash/isEqual';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import apiServiceLocations from '@core/api/apiServiceLocations';
import LogService from '@core/services/LogService';
import searchHotelReservationConstants from '@tenant/app/utils/constants/searchHotelReservation';
import Guests from '@tenants/ticketmaster/app/models/GuestsModel';
import filtersStore from './addHotelReservationStateFilters';
import hotelsStore from './addHotelReservationStateHotels';
import mapStore from './addHotelReservationStateMap';

const STORE_NAME = 'addHotelReservationState';
const DEFAULT_STATE = {
    context: {
        startDateTime: null,
        endDateTime: null,
        quantity: null,
        location: {
            latitude: null,
            longitude: null,
            countryCode: null
        }
    },
    showFilter: false
};

export default {
    name: STORE_NAME,
    namespaced: true,
    modules: {
        filters: filtersStore,
        hotels: hotelsStore,
        map: mapStore
    },
    state: { ...DEFAULT_STATE },
    getters: {
        getLocation: state => state.context.location,
        hasCurrentLocation: ( state ) => {
            const location = state.hotels.filters.location;

            return !!( location.latitude && location.longitude );
        },
        getFiltersFromContext: ( state ) => ( ) => {
            const { context } = state;
            const roomsCount = Math.max( Math.ceil( context.quantity / 2 ), 1 );

            return {
                location: context.location,
                startDateTime: context.startDateTime,
                endDateTime: context.endDateTime,
                roomsCount,
                guests: new Guests( context.quantity ),
                range: searchHotelReservationConstants.DEFAULT.range
            };
        },
        didHotelFiltersChangeFromContext: ( state, getters ) => {
            const contextFilters = getters.getFiltersFromContext();
            //Hack to compare new Guests constructor
            const spreadContextFilters = {
                ...contextFilters,
                guests: { ...contextFilters.guests }
            };
            const hotelsFilters = {
                ...state.hotels.filters,
                guests: { ...state.hotels.filters.guests }
            };
            return  !isEqual( spreadContextFilters, hotelsFilters );
        }
    },
    mutations: {
        [ mutationTypes.CLEAR ]( state ) {
            state.context = {
                startDateTime: null,
                endDateTime: null,
                quantity: null,
                location: {
                    latitude: null,
                    longitude: null,
                    range: null,
                    countryCode: null
                },
            };
        },
        [ mutationTypes.UPDATE ]( state, value ) {
            state.context = {
                startDateTime: value.startDateTime,
                endDateTime: value.endDateTime,
                quantity: value.quantity,
                location: { ...value.location },
                range: value.range
            };
        },
        setShowFilter( state, value ) {
            state.showFilter = value;
        }
    },
    actions: {
        init( { state, commit, dispatch }, context = { } ) {
            const dispatchSubModuleAction = async ( name, arg ) => {
                await dispatch( `addHotelReservationState/${ name }`, arg, { root:true } );
            };
            const oldContext = state.context;
            const oldContextPeriodLocation = {
                startDateTime: oldContext.startDateTime ? oldContext.startDateTime.getTime( ) : null,
                endDateTime: oldContext.endDateTime ? oldContext.endDateTime.getTime( ) : null,
                location: oldContext.location,
                range: oldContext.range,
            };
            const roomsCount = Math.max( Math.ceil( context.quantity / 2 ), 1 );
            const contextPeriodLocation = {
                startDateTime: context.startDateTime ? context.startDateTime.getTime( ) : null,
                endDateTime: context.endDateTime ? context.endDateTime.getTime( ) : null,
                location: context.location,
                range: context.location
            };

            if ( !isEqual( oldContextPeriodLocation, contextPeriodLocation ) ) {
                dispatchSubModuleAction( 'filters/clear', null );
                dispatchSubModuleAction( 'filters/update', {
                    startDateTime: context.startDateTime,
                    endDateTime: context.endDateTime,
                    roomsCount,
                    guests: new Guests( context.quantity ),
                    location: context.location,
                    range: context.range || searchHotelReservationConstants.DEFAULT.range
                } );
                dispatchSubModuleAction( 'map/clear' );
            } else if ( oldContext.quantity !== context.quantity ) {
                dispatchSubModuleAction( 'filters/update', {
                    guests: new Guests( context.quantity, 0, [ ] ),
                    roomsCount,
                } );
                dispatchSubModuleAction( 'hotels/clear' );
            }

            commit( mutationTypes.UPDATE, context );
        },
        setShowFilter( { commit }, value ) {
            commit( 'setShowFilter', value );
        },
        resetFilters( { state, dispatch } ) {
            const dispatchSubModuleAction = async ( name, arg ) => {
                await dispatch( `addHotelReservationState/${ name }`, arg, { root:true } );
            };

            dispatchSubModuleAction( 'filters/clear', null );
            dispatchSubModuleAction( 'filters/update', {
                startDateTime: state.context.startDateTime,
                endDateTime: state.context.endDateTime,
                roomsCount: Math.max( Math.floor( state.context.quantity / 2 ), 1 ),
                guests: new Guests( state.context.quantity ),
                location: state.context.location,
                range: state.context.range || searchHotelReservationConstants.DEFAULT.range
            } );
        },
        resetHotels( { dispatch } ) {
            const dispatchSubModuleAction = async ( name, arg ) => {
                await dispatch( `addHotelReservationState/${ name }`, arg, { root:true } );
            };

            dispatchSubModuleAction( 'hotels/clearList' );
            dispatchSubModuleAction( 'hotels/setInitialLoad', true );
        },
        async updateFilterLocationWithMapCenter ( { dispatch, state } ) {
            const dispatchSubModuleAction = async ( name, arg ) => {
                await dispatch( `addHotelReservationState/${ name }`, arg, { root:true } );
            };
            const latitude = state.map.mapCenter.latitude;
            const longitude = state.map.mapCenter.longitude;

            const api = await apiServiceLocations( '' );
            await dispatchSubModuleAction( 'hotels/clearList' );
            const response = await api.locations.getClosestCity( { latitude, longitude } );

            if ( response.success ) {
                await dispatchSubModuleAction( 'filters/update', {
                    location: {
                        ...response.data,
                        latitude: latitude,
                        longitude: longitude
                    },
                } );
            } else {
                await dispatchSubModuleAction( 'map/setSearchCenter', { latitude, longitude } );
                await dispatchSubModuleAction( 'hotels/setResultsNotFound', true );
                LogService.error( 'Problems identifying a location' );
            }
        },
    }
};
