import cloneDeep from 'lodash/cloneDeep';
import { create as CreateBundleProductsEventTicketModel } from './BundleProductsEventTicketModel';
import { create as CreateBundleProductsHotelReservationModel } from './BundleProductsHotelReservationModel';

export function create( eventTicket, hotelReservation ) {
    return {
        eventTicket: eventTicket || CreateBundleProductsEventTicketModel( ),
        hotelReservation: hotelReservation || CreateBundleProductsHotelReservationModel( ),
        /**
         * Functions
         */
        getTotalsForOthers( type ) {
            if ( type === 'hotel' ) {
                return this.hotelReservation.totals;
            }
            return this.eventTicket.totals;
        },
        getQuantity( ) {
            return {
                ...this.eventTicket.quantity,
                ...this.hotelReservation.quantity
            };
        },
        getTotals( ) {
            const totalsEventTicket = this.eventTicket.totals;
            const totalsHotelReservation = this.hotelReservation.totals;
            const ticketsAllInclusivePricing = this.eventTicket.allInclusivePricing;

            const ret = {
                basePrice: totalsEventTicket.basePrice + totalsHotelReservation.basePrice,
                basePriceWithoutDiscount: totalsEventTicket.basePrice + totalsHotelReservation.basePriceWithoutDiscount,
                discountedAmount: totalsHotelReservation.discountedAmount || 0,
                includesTaxesAndFees: totalsHotelReservation.includesTaxesAndFees || ticketsAllInclusivePricing || false,
                totalPrice: totalsEventTicket.totalPrice + totalsHotelReservation.totalPrice,
                displayPrice: totalsEventTicket.displayPrice + totalsHotelReservation.totalPrice
            };

            return ret;
        },
        hasHotelReservation( ) {
            return this.hotelReservation.hasValue( );
        },
        setHotelReservation( hotelReservation ) {
            this.hotelReservation = hotelReservation;
        },
        setHotelReservationSelectRoom( selectRoom ) {
            if ( this.hotelReservation ) {
                this.hotelReservation.setSelectedRoom( selectRoom );
            }
        },
        setHotelReservationRateForSelectedRoom( selectedRate ) {
            if ( this.hotelReservation ) {
                this.hotelReservation.setSelectedRoomWithRate( selectedRate );
            }
        },
        _clone( ) {
            return cloneDeep( this );
        }
    };
}
