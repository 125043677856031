import apiServicePackageOffers from '@core/api/apiServicePackageOffers';
import mutationTypes from '@core/helpers/storeFactories/helpers/mutationTypes';
import normalizeHotelReservation from '@core/data/response/normalizeHotelReservation';
import LogService from '@core/services/LogService';

const STORE_NAME = 'hotels';

const findRoomForSelectedHotelReservationRoom = ( list, hotelReservation ) => {
    let ret = null;

    list.forEach( item => {
        if ( item.id === hotelReservation.id ) {
            const room = item.rooms.find( room => {
                return room.selectedRate.packageOffer.id === hotelReservation.selectedRoom.selectedRate.packageOffer.id;
            } );

            if ( room ) {
                ret = room;
            }
        }
    } );

    return ret;
};

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        list: [],
        loading: false,
        loadingCounter: 0,
        expandedItem: null,
        minCardHeight: 0
    },
    mutations: {
        setHotelList( state, data ) {
            state.list = [ ...data ];
        },
        setExpandedItem( state, data ) {
            state.expandedItem = data;
        },
        setCardMinHeight( state, data ) {
            state.minCardHeight = data;
        },
        [ mutationTypes.START_LOADING ] ( state ) {
            state.loadingCounter = state.loadingCounter + 1;
            state.loading = !!state.loadingCounter;
        },
        [ mutationTypes.STOP_LOADING ] ( state ) {
            state.loadingCounter = state.loadingCounter > 0 ? state.loadingCounter - 1 : 0;
            state.loading = !!state.loadingCounter;
        },
    },
    getters: {
        getLoading: state => {
            return state.loading;
        },
        findSameRoomForSelectedHotelReservationRoom: state => hotelReservation => {
            return findRoomForSelectedHotelReservationRoom( state.list, hotelReservation );
        },
        get: state => id => state.list.find( item => item.id === id ),
        hasDifferentGuestNumber: state => {
            if ( state.list.length ) {
                return state.list.some( item => item.selectedRoom.accommodation.guests.adultsCount !== state.list[0].selectedRoom.accommodation.guests.adultsCount );
            }
            return false;
        }
    },
    actions: {
        getByEventId: async ( { commit }, { eventId, offerType } = { }, ) => {
            commit( mutationTypes.START_LOADING );
            commit( 'setHotelList', [ ] );

            const api = await apiServicePackageOffers( '' );
            const response = await api.packageOffers.hotelsByEventIdNext( { eventId, offerType } );

            if ( response.success ) {
                const hotels = response.data.list.map( item => {
                    const model = normalizeHotelReservation( item );
                    model.selectedRoom = { ...model.rooms[ 0 ] };
                    model.selectedRoom.selectedRate = { ...model.selectedRoom.rates[ 0] };

                    return model;
                } );

                commit( 'setHotelList', hotels );
                commit( mutationTypes.STOP_LOADING );

                return { hotels: hotels };

            } else {
                LogService.debug( 'problem loading hotels by event ' + eventId );
                commit( mutationTypes.STOP_LOADING );
                return response;
            }
        },
        updateWithSelectedItemIfMatch( { commit, getters }, hotelReservation ) {
            const room = getters.findSameRoomForSelectedHotelReservationRoom( hotelReservation );
            if ( room ) {
                const id = hotelReservation.id;

                commit ( 'selectRoom', { id, value: room } );
                return getters.get( id );
            }

            return false;
        },
        updateExpandedItem: ( { commit }, data ) => {
            commit( 'setExpandedItem', data );
        },
        updateCardMinHeight: ( { commit, state }, data ) => {
            if ( data > state.minCardHeight ) {
                commit( 'setCardMinHeight', data );
            }
        }
    }
};
