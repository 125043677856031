<template>
    <v-app>
        <TheHeader hide-navigation />
        <v-main class="main" :style="mainStyle">
            <!-- Provides the application the proper gutter -->
            <v-container fluid class="pa-0">
                <slot></slot>
            </v-container>
        </v-main>
        <ManageCookiesButton floating />
    </v-app>
</template>

<script>
    import ManageCookiesButton from '@tenant/app/components/misc/ManageCookiesButton.vue';
    import TheHeader from './components/TheHeader.vue';

    export default {
        name: 'App',
        components: {
            TheHeader,
            ManageCookiesButton
        },
        computed: {
            mainStyle() {
                return {
                    paddingTop: '50px'
                };
            }
        }
    };
</script>
