import apiServiceLocations from '@core/api/apiServiceLocations';
import LogService from '@core/services/LogService';
import { normalizeLocation } from '@tenant/app/data/response/normalizeLocation';


const STORE_NAME = 'locations';
const ITEMS_PER_PAGE = 10;

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        list:               [],
        timestamp:          0,
        loading:            0,
        listFirstLoad:      false,
        refresh:            false,
    },
    mutations: {
        setLoading( state, loading ) {
            state.loading = loading ? loading : 0;
        },
        clearList( state ) {
            state.list = [ ];
        },
        setList( state, data ) {
            state.timestamp = new Date( ).getTime( );
            state.listFirstLoad = true;
            state.list = data.map( item => normalizeLocation( item ) );
        },
        setRefresh( state, refresh ) {
            state.refresh = refresh;
        }
    },

    actions: {
        get: async ( { commit, state }, { search, keyword } = { } ) => {
            const api = await apiServiceLocations( '' );

            commit( 'clearList' );
            commit( 'setLoading', state.loading + 1 );
            const response = await api.locations.get( {
                keyword: keyword,
                offset: 0,
                limit:  ITEMS_PER_PAGE,
                ...search
            } );
            commit( 'setLoading', state.loading - 1 );
            if ( response.success ) {
                state.loading || commit( 'setList', response.data.list );

                return state.list;
            } else {
                LogService.debug( 'problem loading locations' );
                return false;
            }
        }
    }
};
