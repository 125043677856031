<template>
    <div class="gst-navigation-mobile">
        <BaseButton icon height="24" width="24" @click="openMenu">
            <img src="@tenant/app/assets/icons/burger.png" alt="ticketmaster" />
        </BaseButton>
        <v-navigation-drawer
            v-model="isOpened"
            class="gst-navigation-mobile__drawer"
            fixed
            hide-overlay
            right
            app
            width="100%">
            <TheNavigation class="gst-navigation-mobile__menu px-4 pt-14" @link-click="isOpened = false" />
            <template v-slot:prepend>
                <div class="gst-navigation-mobile__header">
                    <BaseButton icon class="gst-navigation-mobile__close float-md-none float-lg-none" height="24" width="24" @click="isOpened = false">
                        <BaseIcon class="gst-icon-chevron-left" symbol-id="icons--dropdown_arrow" />
                    </BaseButton>
                    {{ $t('menuTitle') }}
                </div>
            </template>
            <template v-slot:append>
                <div class="d-flex justify-center pb-8 px-6">
                    <LanguageSelectField is-secondary-type show-on-top :has-short-form="false" />
                </div>
                <div class="gst-navigation-mobile__footer pa-4" tabindex="0" @click="isOpened = false" @keypress.esc="isOpened = false">
                    <router-link class="d-flex justify-center py-6" target="_blank" :to="{ name: 'policy' }">
                        {{ $t('linkTermsOfUse') }}
                    </router-link>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import LanguageSelectField from '@tenant/app/components/inputs/LanguageSelectField.vue';
    import TheNavigation from './TheNavigation.vue';

    export default {
        name: 'TheNavigationMobile',
        components: {
            BaseIcon,
            BaseButton,
            LanguageSelectField,
            TheNavigation
        },
        data( ) {
            return {
                isOpened: false
            };
        },
        methods: {
            openMenu( ) {
                this.isOpened = true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-navigation-mobile {
        .gst-navigation-mobile__header {
            position: relative;
            height: 48px;
            line-height: 24px;
            padding: theme-spacing( 3 ) theme-spacing( 4 );
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            border-bottom: 1px solid theme-color( 'septenary' );

            .gst-navigation-mobile__close {
                position: absolute;
                top: theme-spacing( 3 );
                left: theme-spacing( 4 );
            }

            ::v-deep .gst-svg-icon {
                fill: theme-color( 'new-primary' );
            }
        }

        .gst-navigation-mobile__footer {
            background-color: theme-color( 'quinary' );

            a {
                color: theme-color( 'new-quaternary' );
                font-size: 16px;
            }
        }

        .gst-navigation-mobile__menu {
            ::v-deep li:not(:first-child) {
                border-top: 1px solid theme-color( 'septenary' );
            }

            ::v-deep a {
                display: block;
                padding: theme-spacing( 6 ) 0;
                color: theme-color( 'new-quaternary' );
                font-size: 16px;
                font-weight: 700;
            }

            ::v-deep .gst-svg-icon {
                fill: theme-color( 'new-quaternary' );
            }
        }
    }
</style>