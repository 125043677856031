import cloneDeep from 'lodash/cloneDeep';

export function create( event, ticket, section, tickets = 0, totalBasePrice = 0, totalBasePriceWithoutDiscount = 0, totalPrice = 0  ) {
    const allInclusivePricing = !!( ticket?.offers?.[ 0 ].allInclusivePricing );

    return {
        event:      event,
        ticket:     ticket,
        type:       section ? 'section' : null,
        section:    section,
        allInclusivePricing,
        quantity:   {
            tickets: tickets,
        },
        totals: {
            basePrice: totalBasePrice,
            basePriceWithoutDiscount: totalBasePriceWithoutDiscount,
            totalPrice: totalPrice || totalBasePrice,
            displayPrice: allInclusivePricing ? totalPrice : totalBasePrice
        },
        /**
         * Functions
         */
        _clone( ) {
            return cloneDeep( this );
        }
    };
}
