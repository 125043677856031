import { format } from '@core/utils/currencyUtils';

/**
 * Currency value for 'en-US' locale ( no manipulation of the data )
 * @param {Number} value
 * @param {String} currency
 * @param {Object} options
 */
export default function currency( value, currency, options = { digits: 2, currencyDisplay: 'narrowSymbol' }, showFull = true ) {
    options = {
        ...options,
        currencyDisplay: currency === 'MXN' ? 'symbol' : 'narrowSymbol'
    };
    const showFullCurrency = showFull && currency !== 'MXN';
    return format( value, currency, options ) + ( showFullCurrency ? ` ${ currency }`: '' );
}