import { sortCompare } from '@core/utils/sortCompareUtils';
import LogService from '@core/services/LogService';
import hostnameConstants from './constants/hostname';
import environmentConstants from './constants/environment';

const checkHostnameExist = function ( hostname ) {
    const hostnameEntries = Object.entries( hostnameConstants );
    let hostnamesFound = [];

    hostnameEntries.some( entry => {
        const entryHostnamesFound = checkHostnameByEntryType( hostname, entry );
        hostnamesFound = [
            ...hostnamesFound,
            ...entryHostnamesFound
        ];

        return hostnamesFound.length;
    } );

    hostnamesFound.sort( ( a, b ) => sortCompare( a.priority, b.priority ) ); // sort ascending by priority

    hostnamesFound = hostnamesFound.map( item => {
        return {
            environment: item.environment,
            value: item.value
        };
    } );

    return hostnamesFound;
};

const checkHostnameByEntryType = function ( hostname, entry ) {
    let hostnamesFound = [];
    const entryHostnameList = Object.values( entry[ 1 ] );
    const entryKey = entry[ 0 ];

    const getFormattedHostnameFound = function ( keys, index, entryKey, value, listValuesFound = [], isListAndNotEmpty = false ) {
        const environmentTypePriority = {
            LOCALHOST: 4,
            TEST: 3,
            UAT: 2,
            PRODUCTION: 1,
        };

        return {
            environment: keys[ index ],
            priority: environmentTypePriority[ keys[ index ] ],
            entry: entryKey,
            value: isListAndNotEmpty ? listValuesFound : value,
        };
    };

    const ret = entryHostnameList.reduce( ( acc, value, index ) => {
        const keys = Object.keys( hostnameConstants[ entryKey ] );
        const isListAndNotEmpty = Array.isArray( value ) && value.length;

        if ( entryKey === 'equal' ) {
            const listValuesFound = isListAndNotEmpty ? value.filter( item => hostname === item ) : [];
            const hostnameItemFound = getFormattedHostnameFound( keys, index, entryKey, value, listValuesFound, isListAndNotEmpty );

            if ( isListAndNotEmpty && listValuesFound.length || ( !isListAndNotEmpty && hostname === value ) ) {
                return acc = [
                    ...acc,
                    hostnameItemFound
                ];
            }
        }
        else if ( entryKey === 'include' ) {
            const listValuesFound = isListAndNotEmpty ? value.filter( item => hostname.includes( item ) ) : [];
            const hostnameItemFound = getFormattedHostnameFound( keys, index, entryKey, value, listValuesFound, isListAndNotEmpty );

            if ( isListAndNotEmpty && listValuesFound.length || ( !isListAndNotEmpty && hostname.includes( value ) ) ) {
                return acc = [
                    ...acc,
                    hostnameItemFound
                ];
            }
        }
        else if ( entryKey === 'regex' ) {
            const listValuesFound = isListAndNotEmpty ? value.filter( item => item.test( hostname ) ) : [];
            const hostnameItemFound = getFormattedHostnameFound( keys, index, entryKey, value, listValuesFound, isListAndNotEmpty );

            if ( isListAndNotEmpty && listValuesFound.length || ( !isListAndNotEmpty && value.test( hostname ) ) ) {
                return acc = [
                    ...acc,
                    hostnameItemFound
                ];
            }
        }

        return acc;
    }, [] );

    hostnamesFound = [
        ...hostnamesFound,
        ...ret
    ];

    return hostnamesFound;
};

export function getType( ) {
    const hostname = window.location.hostname;
    let hostnamesFound = checkHostnameExist( hostname );

    if ( hostnamesFound.length ) {
        const environmentType = Array.isArray( hostnamesFound[ 0 ] ) ? hostnamesFound[ 0 ][ 0 ].environment : hostnamesFound[ 0 ].environment;

        if ( hostnamesFound.length > 1 ) {
            LogService.error( 'Multiple hostnames found: ', hostnamesFound );
        }

        return environmentConstants.TYPES[ environmentType ];
    }

    LogService.error( 'Hostnames found' );

    return null;
}

export function isProduction( ) {
    const hostnameType = getType( );

    return hostnameType === environmentConstants.TYPES.PRODUCTION;
}
