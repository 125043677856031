
let keycloak;

export default ( ( ) => {
    return {
        getInstance(  ) {
            return keycloak;
        },
        /**
         * create new instance of keycloak
         */
        create: async ( ) => { },
        /**
         *
         * @param tokens
         * @param tokens.accessToken string
         * @param tokens.refreshToken string
         * @param fromLocalStorage
         * @returns {Promise<undefined|boolean>}
         */
        // eslint-disable-next-line no-unused-vars
        init: async( tokens= { }, fromLocalStorage = true ) => {
            return false;
        },
        /**
         * Get Access Token
         * To be sent on each request
         *
         * @returns {*|null}
         */
        getAccessToken: ( ) => keycloak?.token,
        /**
         * Check if storage has tokens
         * Used when application starts to know if the user
         * was logged before
         *
         * @returns {boolean}
         */
        hasStorageTokens: ( ) => false,
        /**
         * Clear the data that is stored by the service.
         * Include cleaning storage data and intervals
         */
        clear: ( ) => { },
        /**
         * Get URL Login for SSO
         * @param redirectUri
         * @param locale
         */
        // eslint-disable-next-line no-unused-vars
        getLoginUrl: ( redirectUri, locale ) => { },
        /**
         * Get URL logout for SSO
         * @param redirectUri
         */
        // eslint-disable-next-line no-unused-vars
        getLogoutUrl: ( redirectUri ) => { },
    };
} ) ( );
