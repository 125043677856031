import i18next from 'i18next';
import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
import apiServiceCart from '@core/api/apiServiceCart';
import productsConstants from '@core/utils/constants/products';
import normalizeCartInvoice from '@core/data/response/normalizeCartInvoice';
import offerConstants from '@core/utils/constants/offer';
import LogService from '@core/services/LogService';
import { normalizeShippingOption } from '@core/data/response/normalizeShippingOption';
import apiConstants from '@core/utils/constants/api';
import { normalizeCart } from '@tenant/app/data/response/normalizeCart';
import { normalizeCartErrorResponse } from '@tenant/app/data/response/normalizeCartErrorResponse';
import { normalizeOrder } from '@tenant/app/data/response/normalizeOrder';
import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';
import LocalStorageService from '@tenant/app/services/LocalStorageService';


const STORE_NAME = 'cart';
const DEFAULT_STATE = {
    list: [],
    current: {
        id: null,
        expireAt: null,
        submittedAt: null,
        collections: [],
        order: {},
        venueSeatMapUrl: null,
        packageOffer: {},
        isSubmitting: false // Flag to know when cart is submitting
    }
};

export default {
    name: STORE_NAME,
    namespaced: true,
    state: { ...DEFAULT_STATE },
    getters: {
        cartId: ( state ) => state.current.id,
        isCollectionIdIntoCart: ( state ) => id => state.current.collections.find ( item => item.id === id ),
        isExpired: ( state ) => ( ) => {
            const { expireAt } = state.current;

            return !expireAt || ( expireAt.getTime( ) < new Date( ).getTime( ) );
        },
        isSubmitted: ( state ) => !!state.current.submittedAt,
        isCartSubmitting: ( state ) => !!state.current.isSubmitting,
        getCollections: ( state ) => state.current.collections,
        hasTicket: ( state ) => {
            return !!state.current.id && !!state.current.collections.find( item => item.productTypeId === productsConstants.TYPES.TICKET );
        },
        hasHotelReservation: ( state ) => {
            return !!state.current.id && !!state.current.collections.find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION );
        },
        hasHotelReservationOnly: ( state, getters ) => {
            return state.current.collections.length === 1 && getters.hasHotelReservation;
        },
        hasTicketOnly: ( state, getters ) => {
            return state.current.collections.length === 1 && getters.hasTicket;
        },
        packageOfferType: state => {
            const packageOffer = state.current.packageOffer;

            return packageOffer ? packageOffer.type : null;
        },
        event: state => {
            const ticket = state.current.collections.find( item => item.productTypeId === productsConstants.TYPES.TICKET ) || { };

            return ticket.info;
        },
        eventCountry: ( state, getters ) => {
            const event = getters.event || { };

            return event.venueCountryCode;
        },
        hotel: ( state ) => {
            const hotelReservation = state.current.collections.find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION ) || { };

            return hotelReservation.hotel;
        },
        hotelCountry: ( state, getters ) => {
            const hotel = getters.hotel || { address: { } };

            return hotel.address.countryCode;
        },
        purchaseFlow: ( state, getters ) => {
            const { packageOfferType } = getters;
            const { FLOWS } = purchaseConstants.CART;

            if ( packageOfferType === offerConstants.TYPES.HOTEL_ONLY ) {
                return FLOWS.HOTELS_PRE_ALLOCATED;
            }
            if ( packageOfferType === offerConstants.TYPES.PRE_BUNDLE_PACKAGE ) {
                return FLOWS.PACKAGES;
            }
            if ( packageOfferType === offerConstants.TYPES.EXPERIENCES_PACKAGE ) {
                return FLOWS.EXPERIENCES;
            }
            if ( getters.hasHotelReservationOnly ) {
                return FLOWS.HOTELS_OPEN_INVENTORY;
            }

            return FLOWS.DYNAMIC;
        },
        purchaseCountry: ( state, getters ) => {
            return getters.eventCountry || getters.hotelCountry || 'US';
        }
    },
    mutations: {
        clear( state ) {
            state.current = {
                id: null,
                expireAt: null,
                submittedAt: null,
                collections: [],
                isSubmitting: false
            };
        },
        setCart( state, { cart, collections, order, packageOffer } ) {
            state.current = {
                ...cart,
                collections,
                order,
                packageOffer,
            };
        },
        setCartSubmittedAt( state, value ) {
            state.current.submittedAt = value;
        },
        setCartIsSubmitting( state, value ) {
            state.current.isSubmitting = value;
        },
        addToList( state, data ) {
            state.list = [
                ...state.list,
                {
                    ...data
                }
            ];
        },
        removeFromList( state, id ) {
            state.list = state.list.filter( item => item.id !== id );
        },
        setCartOrder( state, value ) {
            state.current.order = value;
        }
    },
    actions: {
        get: async ( { commit, dispatch, state }, { id } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.get( id );

            if ( response.success ) {
                const { cart, collections, order, packageOffer } = normalizeCart( response.data );

                commit( 'setCart', {
                    cart: {
                        ...cart,
                        expireAt: state.current.expireAt,
                        submittedAt: state.current.submittedAt,
                        isSubmitting: state.current.isSubmitting,
                        venueSeatMapUrl: state.current.venueSeatMapUrl,
                        hasTicket: state.current.hasTicket,
                        hasHotelReservation: state.current.hasHotelReservation,
                        hasHotelReservationOnly: state.current.hasHotelReservationOnly
                    },
                    collections,
                    order,
                    packageOffer
                } );
                if ( response.data.hint?.code === apiConstants.WARNING_CODES.EVENT_PROVIDER.FAN_SESSION_RESALE_EXPIRED ) {
                    dispatch( 'notification/info',
                        {
                            content: i18next.t( '_common:messages.eventTicketStatus.sessionChange.message' ),
                        },
                        { root: true }
                    );
                }
                return state.current.id;
            } else {
                LogService.debug( 'Problem loading cart with id' + id );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        setCartOrder( { commit }, data ) {
            commit( 'setCartOrder', data );
        },
        clear: async ( { commit } ) => {
            commit( 'clear' );
        },
        addItem: async ( { commit, dispatch, state }, { ticket, hotelReservation, offer, searchedLocation } ) => {
            if ( state.current && state.current.id ) {
                await dispatch( 'delete', { id: state.current.id } );
                commit( 'clear' );
            }
            const token = LocalStorageService.userToken.get( );
            if ( token ) {
                hotelReservation.token = token;
            }
            const api = await apiServiceCart( '' );
            const response = await api.cart.addItem( ticket, hotelReservation, offer, searchedLocation );
            if ( response.success ) {
                const { cart, collections, order } = normalizeCart( response.data );

                commit( 'setCart', {
                    cart: {
                        ...cart,
                        submittedAt: null,
                        venueSeatMapUrl: ticket ? ticket.venueSeatMapUrl : null,
                    },
                    collections,
                    order
                } );
                commit( 'addToList', state.current );

                const ret = {
                    success: true,
                    cartId: state.current.id
                };

                return cart.hintCode ? { ...ret, code: cart.hintCode, totalPrice: order.totalPrice } : ret;
            } else {
                return normalizeCartErrorResponse( response );
            }
        },
        addShippingOption: async ( { dispatch }, { id, data } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.addShippingOption( id, data );

            if ( response.success ) {
                const { order } = normalizeCart ( response.data );

                return {
                    order
                };
            } else {
                LogService.debug( 'Problem adding shipping option' );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        getShippingOptions: async ( { dispatch }, { id, data } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.getShippingOptions( id, data );

            if ( response.success ) {
                return response.data.map( normalizeShippingOption );
            } else {
                LogService.debug( 'Problem loading shipping options' );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        checkout: async ( { commit }, { id, data } ) => {
            commit( 'setCartSubmittedAt', new Date() );
            commit( 'setCartIsSubmitting', true );
            const token = LocalStorageService.userToken.get( );
            if ( token ) {
                data.token = token;
            }

            const api = await apiServiceCart( '' );
            const response = await api.cart.checkout( id, data );

            if ( response.success ) {
                commit( 'clear' );
                commit( 'removeFromList', id );
                return {
                    success: true,
                    ...normalizeOrder( response.data )
                };
            } else {
                commit( 'setCartIsSubmitting', false );
                return response;
            }
        },
        delete: async ( { commit, state }, { id } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.delete ( id );

            if ( response.success ) {
                state.id !== id || commit( 'clear' );
                commit( 'removeFromList', id );
                return id;
            } else {
                LogService.debug( 'Problem deleting cart with id ', id );
                return false;
            }
        },
        instantDelete: async( { commit, state }, { id } ) => {
            state.id || commit( 'clear' );
            commit( 'removeFromList', id );
            const api = await apiServiceCart( '' );
            const response = await api.cart.delete ( id );
            if ( response.success ) {
                return id;
            } else {
                LogService.debug( 'Problem deleting cart with id ', id );
                return false;
            }
        },
        instantDeleteCurrentCart: async( { state, commit, dispatch } ) => {
            const { id, isSubmitting } = state.current;

            if ( id && !isSubmitting ) {
                commit( 'clear' );
                await dispatch( 'delete', { id } );
            }
            return true;
        },
        instantDeleteCurrentCartIfExpired: async( { getters, state, dispatch } ) => {
            const { id, expireAt } = state.current;

            if ( id && expireAt && getters[ 'isExpired' ]( ) ) {
                return await dispatch( 'instantDeleteCurrentCart' );
            }
            return true;
        },
        deleteAllIfExpired: async( { state, dispatch } ) => {
            const nowTimestamp = new Date( ).getTime( );

            state.list
                .filter( item => !item.expireAt || item.expireAt.getTime( ) < nowTimestamp )
                .forEach( item => {
                    dispatch( 'instantDelete', { id: item.id } );
                } );
        },
        /**
         * @param {Object} context 
         * @param {*} param1 
         * @param {String} param1.id cart id 
         * @returns {Promise<void>}
         */
        createInvoice: async( context, { id, data } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.createInvoice( id, data );
            if ( response.success ) {
                return {
                    success: true,
                    data: normalizeCartInvoice( response.data )
                };
            } else {
                return response;
            }
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            const storeState = state[ STORE_NAME ];
            const { list } = storeState;

            return {
                list: list.length ? list.map( item => {
                    return {
                        id: item.id,
                        expireAt: item.expireAt
                    };
                } ): [ ],
            };
        },
        getDataToLoad: ( data ) => {
            try {
                return {
                    list: data.list ? data.list.map( item => {
                        return {
                            ...item,
                            expireAt: new Date( item.expireAt )
                        };
                    } ): [ ],
                };
            } catch ( e ) {
                LogService.error( `Vuex state cannot be loaded: Cart: ${ e }` );
                return null;
            }
        }
    },
    _sessionPersist: {
        getDataToSave: ( state ) => {
            const storeState = state[ STORE_NAME ];
            const { current } = storeState;

            return {
                current: {
                    id: current.id,
                    expireAt: current.expireAt,
                    venueSeatMapUrl: current.venueSeatMapUrl
                }
            };
        },
        getDataToLoad: ( data ) => {
            try {
                return {
                    current: data.current ? {
                        ...data.current,
                        expireAt: new Date( data.current.expireAt )
                    } : {
                        id: null,
                        expireAt: null,
                    }
                };
            } catch ( e ) {
                LogService.error( `Vuex session state cannot be loaded: Cart: ${ e }` );
                return null;
            }
        }
    }
};
