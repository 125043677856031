const TheHome                      = ( ) => import( '@tenant/app/views/home/TheHome' );
const TheEvent                      = ( ) => import( '@tenant/app/views/eventV2/TheEvent' );
const TheEventPackageOffer          = ( ) => import( '@tenant/app/views/eventV2/TheEventPackageOffer' );
const TheEventPostPurchase          = ( ) => import( '@tenant/app/views/eventV2/TheEventPostPurchase' );
const EventReservation              = ( ) => import( '@tenant/app/views/eventV2/TheEvent/EventReservation' );
const EventReservationPostPurchase  = ( ) => import( '@tenant/app/views/eventV2/TheEventPostPurchase/EventReservation' );
const EventInfoModal                = ( ) => import( '@tenant/app/views/eventV2/EventInfoModal' );
const TheCheckout                   = ( ) => import( '@tenant/app/views/cart/TheCheckout' );
const TheOrderConfirmation          = ( ) => import( '@tenant/app/views/cart/TheOrderConfirmation' );
const ThePackageOffer               = ( ) => import( '@tenant/app/views/packageOffer/ThePackageOffer' );
const TheEventMarketing    = ( ) => import ( '@tenants/ticketmaster/app/views/eventMarketing/TheEventMarketing.vue' );
const NotFound               = ( ) => import( '@tenant/app/views/notFound/NotFound.vue' );
const TheAttraction    = ( ) => import ( '@tenants/ticketmaster/app/views/attraction/TheAttraction.vue' );
const TheVenue = () => import( '@tenant/app/views/venue/TheVenue.vue' );

const Policy                        = ( ) => import( '@tenants/ticketmaster/app/static/Policy/Policy.vue' );
const Help                          = ( ) => import( '@core/static/Help/Help.vue' );

const TheSearch                            = ( ) => import( '@tenant/app/views/search/TheSearch' );
const SearchResultTabsAll               = ( ) => import( '@tenant/app/views/search/TheSearch/SearchResultTabsAll' );
const SearchResultTabsAttractions   = ( ) => import( '@tenant/app/views/search/TheSearch/SearchResultTabsAttractions' );
const SearchResultTabsEvents         = ( ) => import( '@tenant/app/views/search/TheSearch/SearchResultTabsEvents' );
const SearchResultTabsVenues        = ( ) => import( '@tenant/app/views/search/TheSearch/SearchResultTabsVenues' );

/**
 * 
 * @typedef {Object} RouteMeta - Meta for route
 * @property {String} layout - Blank || headerOnly
 * @property {Boolean} isPublic - Public route or not
 * @property {Boolean} suppressLangReset - When true the available languages from the store will not be reset
 * @property {Object} sitemap - Information for sitemap
 * @property {Number} sitemap.priority - The priority of this URL relative to other URLs on your site
 * @property {String} sitemap.changefreq - How frequently the page is likely to change
 * @property {String} sitemap.ignoreRoute - ignore route when sitemap is generated
 * @property {Object} defaultDocumentTitle - when true on routeChange it will change the title to the default one ( Home )
 */

export default [
    {
        name: 'home',
        path: '/',
        component: TheHome,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                priority:    1,
                changefreq: 'daily',
            },
            defaultDocumentTitle: true 
        },
    },
    {
        name: 'eventPackageOffer',
        path: '/event-package-offer/:id',
        component: TheEventPackageOffer,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            },
        },
    },
    {
        name: 'tm-eventPackageOffer',
        path: '/tm-event-package-offer/:id',
        component: TheEventPackageOffer,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            },
            customDocumentTitle: true
        },
    },
    {
        name: 'event',
        path: '/event/:id',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'event.reservation',
                path: '/event/:id/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'event'
                },
                props: true
            },
        ]
    },
    /**
     * other query parameters
     *
     * selectOfferId - select experience package offer
     */
    {
        name: 'eventWithOffer',
        path: '/event/:id/:offerType',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'eventWithOffer.reservation',
                path: '/event/:id/:offerType/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'eventWithOffer'
                },
                props: true
            },
        ]
    },
    {
        name: 'tmEventWithOffer',
        path: '/tm-event/:id/:offerType',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'tmEventWithOffer.reservation',
                path: '/tm-event/:id/:offerType/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'eventWithOffer'
                },
                props: true
            },
        ]
    },
    {
        name: 'tm-event',
        path: '/tm-event/:id',
        component: TheEvent,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'tm-event.reservation',
                path: '/tm-event/:id/reservation',
                component: EventReservation,
                meta: {
                    layout: 'blank',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'event'
                },
                props: true
            },
        ]
    },
    {
        name: 'eventHotels',
        path: '/event-hotels/:id',
        component: TheEventPostPurchase,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'eventHotels.reservation',
                path: '/event-hotels/:id/reservation',
                component: EventReservationPostPurchase,
                meta: {
                    layout: 'headerOnly',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'eventHotels'
                },
                props: true
            },
        ]
    },
    {
        name: 'hotels',
        path: '/hotels',
        component: TheEventPostPurchase,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                priority:    0.5,
                changefreq: 'daily',
            }
        },
        children: [
            {
                name: 'hotels.reservation',
                path: '/hotels/reservation',
                component: EventReservationPostPurchase,
                meta: {
                    layout: 'headerOnly',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'hotels'
                },
                props: true
            },
        ]
    },
    {
        name: 'tm-eventHotels',
        path: '/tm-event-hotels/:id',
        component: TheEventPostPurchase,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
        children: [
            {
                name: 'tm-eventHotels.reservation',
                path: '/tm-event-hotels/:id/reservation',
                component: EventReservationPostPurchase,
                meta: {
                    layout: 'headerOnly',
                    isPublic: true,
                    suppressLangReset: true,
                    routeRedirectOnError: 'tm-eventHotels'
                },
                props: true
            },
        ]
    },
    {
        name: 'eventInfo',
        path: '/event/:id/info',
        meta: {
            type: 'modal',
            modalConfig: {
                component: EventInfoModal,
                props: {},
                config: {
                },
                configDesktop: {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    persistent: false,
                    'hide-overlay': false,
                    'max-width': 600,
                    'min-height': 800,
                    'content-class': 'gst-v-dialog gst-v-dialog-event-info',
                },
                configMobile: {
                    'content-class': 'v-dialog__height-90 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    persistent: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition',
                }
            },
            isPublic: true,
            suppressLangReset: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'cartCheckout',
        path: '/checkout/:cartId/:step?',
        component: TheCheckout,
        meta: {
            layout: 'blank',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'OrderConfirmation',
        path: '/order-confirmation/:uuid?',
        component: TheOrderConfirmation,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'packageOffer',
        path: '/packages/:id',
        component: ThePackageOffer,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        name: 'eventMarketing',
        path: '/event-marketing/:id',
        component: TheEventMarketing,
        meta: {
            layout: 'headerOnly',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            }
        },
    },
    {
        name: 'attraction',
        path: '/attraction/:id',
        component: TheAttraction,
        meta: {
            layout: 'new',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            },
            defaultDocumentTitle: true 
        }
    },
    {
        name: 'venue',
        path: '/venue/:id',
        component: TheVenue,
        meta: {
            layout: 'new',
            isPublic: true,
            sitemap: {
                priority:    0.5,
                changefreq: 'daily',
            },
            defaultDocumentTitle: true 
        }
    },
    {
        path: '/search',
        component: TheSearch,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            },
            defaultDocumentTitle: true 
        },
        children: [
            {
                path: '/',
                name: 'search',
                component: SearchResultTabsAll,
                meta: {
                    isPublic: true
                },
                defaultDocumentTitle: true 
            },
            {
                path: 'attractions',
                name: 'search.attractions',
                component: SearchResultTabsAttractions,
                meta: {
                    isPublic: true
                },
                defaultDocumentTitle: true 
            },
            {
                path: 'events',
                name: 'search.events',
                component: SearchResultTabsEvents,
                meta: {
                    isPublic: true
                },
                defaultDocumentTitle: true 
            },
            {
                path: 'venues',
                name: 'search.venues',
                component: SearchResultTabsVenues,
                meta: {
                    isPublic: true
                },
                defaultDocumentTitle: true 
            }
        ]
    },
    // --- STATIC --- //
    {
        name: 'help',
        path: '/help',
        component: Help,
        meta: {
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            },
            defaultDocumentTitle: true 
        },
    },
    {
        name: 'policy',
        path: '/policy',
        component: Policy,
        meta: {
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        },
    },
    {
        name: 'policyPackageOffer',
        path: '/policy-package-offer',
        component: Policy,
        meta: {
            isPublic: true,
            sitemap: {
                priority:    0.3,
                changefreq: 'monthly',
            }
        },
    },
    {
        name: 'notFound',
        path: '/404',
        component: NotFound,
        meta: {
            layout: 'new',
            isPublic: true,
            sitemap: {
                ignoreRoute: true
            },
            defaultDocumentTitle: true 
        }
    },
    {
        name: 'routeNotFoundFallback',
        path: '*',
        redirect: '/404',
        component: NotFound,
        meta: {
            isPublic: true,
            sitemap: {
                ignoreRoute: true,
            },
            defaultDocumentTitle: true 
        }
    }
];
