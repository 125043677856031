import i18next from 'i18next';
import dateConstants from '@core/utils/constants/date';
import dateFilter from '@tenant/app/filters/date';
import { getPrice } from '@tenant/app/utils/hotelReservationCancellationPolicies';
import currencyFilter from '@tenant/app/filters/currency';

export default function  ( cancellationPolicy, currency, basePrice, accommodationNights ) {
    if ( cancellationPolicy && cancellationPolicy.fullCharge ) {
        return i18next.t( '_common:terms.fullChargeCancellation' );

    } else if ( cancellationPolicy && cancellationPolicy.fromAt ) {
        const time =  dateFilter( cancellationPolicy.fromAt, dateConstants.FORMAT.TIME );
        const date =  dateFilter( cancellationPolicy.fromAt, dateConstants.FORMAT.UI_DATE );
        const amount = currencyFilter( getPrice( cancellationPolicy, basePrice, accommodationNights ), currency );

        if ( new Date() >= new Date( cancellationPolicy.fromAt ) ) {
            return i18next.t( '_common:terms.freeCancellationCharge', { amount, date, time } );
        }

        return i18next.t(
            '_common:terms.freeCancellationBefore',
            {
                time,
                date
            }
        );
    } else {
        return i18next.t( '_common:terms.freeCancellation' );
    }
}