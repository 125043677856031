export function normalizeCartErrorResponse( response ) {
    return {
        ...response,
        detail: {
            giftCard: {
                newAmount: response.detail?.giftCard?.newAmount || 0,
                oldAmount: response.detail?.giftCard?.oldAmount || 0
            },
            hotelRoom: {
                newRateId: response.detail?.hotelRoom.newRateId || '',
                newTotalPrice: response.detail?.hotelRoom.newTotalPrice || 0,
                oldTotalPrice: response.detail?.hotelRoom.oldTotalPrice || 0
            },
            total: {
                newTotalPrice: response.detail?.total.newTotalPrice || 0,
                oldTotalPrice: response.detail?.total.oldTotalPrice || 0
            }
        }
    };
}
