export default {
    TYPES: {
        STANDARD: 'standard',
        RESALE: 'resale'
    },
    //SOURCE contains types of source tickets of an event. 
    // 'TMR' are considered ThirdPartyEvents (3PE -  TM is not the primary ticketing provider) 
    // 'TICKETMASTER' TM is the primary ticketing provider (resale and/or standard)
    SOURCE: {
        TMR: 'TMR',
        TICKETMASTER: 'TICKETMASTER'
    }
};