import apiConstants from '@core/utils/constants/api';
import {
    normalizeQueryParams as apiUtilsNormalizeQueryParams,
} from '@core/utils/apiUtils';
import { createApiService, fetchAPI } from '@core/api/_utils';

let api = {
    countries: {
        get: ( { filter } ) => {
            return fetchAPI.getList(
                `${apiConstants.BASE_URL}/api/countries`,
                apiUtilsNormalizeQueryParams( filter )
            );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}