<template>
    <div>
        <v-dialog
            v-model="visibility"
            :attach="attachTo"
            :style="modalAttrs.style"
            v-bind="modalAttrs"
            :content-class="contentClass">
            <component
                :is="modal.component"
                class="gst-modal-wrapper gst-modal-wrapper--dynamic"
                v-bind="componentAttrs"
                @close="visibility = false" />
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'ModalDynamicWrapper',
        props: {
            modal: {
                type: Object,
                default: () => { return { }; }
            },
            attachTo: {
                type: String,
                default: '#app'
            }
        },
        data( ) {
            return {
                visibility: false
            };
        },
        computed: {

            modalAttrs() {
                return this.$vuetify.breakpoint.mdAndUp ? this.modal.modalAttrs.desktop : this.modal.modalAttrs.mobile;
            },
            contentClass() {
                const modalContentClass = this.modalAttrs['content-class'];

                if( this.$vuetify.breakpoint.mdAndUp ) {
                    return modalContentClass;
                }

                return `${modalContentClass || ''} v-bottom-sheet`;
            },
            transition() {
                if( this.$vuetify.breakpoint.mdAndUp ) {
                    return this.modalAttrs.transition;
                }

                return this.modalAttrs.transition || 'dialog-bottom-transition';
            },
            componentAttrs() {
                return this.$vuetify.breakpoint.mdAndUp ? this.modal.componentAttrs.desktop : this.modal.componentAttrs.mobile;
            }
        },
        watch: {
            visibility: function ( newValue ) {
                if ( !newValue ) {
                    !this.modal.events.beforeClose || this.modal.events.beforeClose( );
                    setTimeout( ( ) => {
                        !this.modal.events.close || this.modal.events.close( );
                        this.$emit( 'close' );
                    }, 200 );
                } else {
                    !this.modal.events.beforeOpen || this.modal.events.beforeOpen( );
                    setTimeout( ( ) => {
                        !this.modal.events.open || this.modal.events.open( );
                        this.$emit( 'open' );
                    }, 200 );
                }
            }
        },
        mounted( ) {
            this.visibility = true;
        }
    };
</script>
