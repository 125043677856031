import {
    parseDateString as dateUtilsParseDateString
} from '@core/utils/dateUtils';
import normalizeResponsiveImage from './responsiveImage';
import normalizeAddress from './address';
import normalizeHotelRoom from './hotelRoom';
import normalizeAccommodation from './accomodation';
import normalizePackageHighlight from './packageHighlight';

export default function normalize( data ) {
    const address = data.address || {};
    const accommodation = normalizeAccommodation( data.stayRange, data.roomCriteria );

    return {
        id: data.propertyId || null,
        name: data.hotelName || '',
        description: data.hotelDescription || '',
        latitude: data.latitude || 0,
        longitude: data.longitude || 0,
        distance: data.distance || 0,
        address: normalizeAddress( {
            address: address.addressLine || '',
            cityName: address.cityName || '',
            countryCode: address.countryCode || null,
            stateCode: address.stateCode || null,
            zipCode: address.postalCode || null,
        } ),
        cancelPenalty: normalizeHotelCancelPenalty( data.cancelPenalty ),
        email: data.email || null,
        policy: normalizeHotelPolicy( data ),
        image: normalizeResponsiveImage( data.image ),
        images: Array.isArray( data.images ) ? data.images.map( normalizeResponsiveImage ) : [],
        feePolicies: Array.isArray( data.feePolicies ) ? data.feePolicies.map( normalizeHotelFeePolicy ) : [],
        rooms: Array.isArray( data.guestRooms ) ? data.guestRooms.map( item => normalizeHotelRoom( item, accommodation ) ) : [],
        attractions: Array.isArray( data.attractions ) ? data.attractions.map( normalizeHotelAttraction ) : [],
        awards: Array.isArray( data.awards ) ? data.awards.map( normalizeHotelAward ) : [],
        publicTransportation: Array.isArray( data.publicTransportation ) ? data.publicTransportation.map( normalizeHotelPublicTransportation ) : [],
        refPoints: Array.isArray( data.refPoints ) ? data.refPoints.map( normalizeHotelRefPoint ) : [],
        restaurants: Array.isArray( data.restaurants ) ? data.restaurants.map( normalizeHotelRestaurant ) : [],
        services: Array.isArray( data.services ) ? data.services.map( normalizeHotelService ) : [],
        terminals: Array.isArray( data.terminals ) ? data.terminals.map( normalizeHotelTerminal ) : [],
        packageHighlights: Array.isArray( data.packageHighlights ) ? data.packageHighlights.map( normalizePackageHighlight ) : [ ]
    };
}

const normalizeHotelAttraction = ( data ) => {
    return {
        name: data.attractionName || '',
        distance: data.distance || null,
    };
};

const normalizeHotelAward = ( data ) => {
    return {
        rating: Number( data.rating ) || null,
        ratingSymbol: data.ratingSymbol || null,
    };
};

const normalizeHotelCancelPenalty = ( data ) => {
    return {
        absoluteDeadline: Number( data.absoluteDeadline ) || null,
        penaltyDescription: data.absoluteDeadline || '',
    };
};

const normalizeHotelFeePolicy = ( data ) => {
    return {
        name: data.policyName || '',
        amount: Number( data.amount ) || null,
        percent: data.percent || null,
    };
};

const normalizeHotelPublicTransportation = ( data ) => {
    return {
        name: data.transportationDescription || '',
        distance: data.distance || 0,
    };
};

const normalizeHotelRefPoint = ( data ) => {
    return {
        name: data.refPointName || '',
        distance: data.distance || 0,
    };
};

const normalizeHotelRestaurant = ( data ) => {
    return {
        type: data.type || null,
        cuisine: data.cuisine || null,
    };
};

const normalizeHotelPolicy = ( data ) => {
    const policyInfo = data.policyInfo;
    const { stayRange } = data;

    let checkInDateTime, checkOutDateTime;

    if ( policyInfo.checkInTime ) {
        checkInDateTime = dateUtilsParseDateString( `${stayRange.checkin}T${policyInfo.checkInTime}` );
    } else {
        checkInDateTime = dateUtilsParseDateString( `${stayRange.checkin}` );
    }
    if ( policyInfo.checkOutTime ) {
        checkOutDateTime = dateUtilsParseDateString( `${stayRange.checkout}T${policyInfo.checkOutTime}` );
    } else {
        checkOutDateTime = dateUtilsParseDateString( `${stayRange.checkout}` );
    }

    return {
        checkInDateTime: checkInDateTime,
        checkInTime: policyInfo.checkInTime,
        checkOutDateTime: checkOutDateTime,
        checkOutTime: policyInfo.checkOutTime,
    };
};

const normalizeHotelService = ( data ) => {
    return {
        id: data.code || null,
        name: data.serviceName || '',
        quantity: data.quantity || 0,
        amount: data.amount || 0,
        price: data.price || 0,
        currency: data.currency || null,
        timeFrom: data.timeFrom || null,
        timeTo: data.timeTo || null,
    };
};

const normalizeHotelTerminal = ( data ) => {
    return {
        name: data.terminalName || '',
        distance: data.distance || 0,
    };
};