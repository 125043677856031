<template>
    <header class="gst-header-new">
        <router-link :to="{ name: 'home' }" class="flex-grow-0 flex-shrink-0">
            <TheLogo />
        </router-link>
        <div class="d-flex">
            <TheNavigation v-if="$vuetify.breakpoint.mdAndUp && !hideNavigation" class="gst-header__navigation d-flex" />
            <TheNavigationMobile v-else-if="!hideNavigation" />
            <LanguageSelectField v-if="$vuetify.breakpoint.mdAndUp || hideNavigation" class="ml-6" />
        </div>
    </header>
</template>

<script>
    import LanguageSelectField from '@tenant/app/components/inputs/LanguageSelectField.vue';
    import TheNavigation from './TheNavigation.vue';
    import TheNavigationMobile from './TheNavigationMobile.vue';
    import TheLogo from './TheLogo.vue';

    export default {
        name: 'TheHeader',
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'views.home.theHeader'
        },
        components: {
            TheNavigation,
            TheNavigationMobile,
            TheLogo,
            LanguageSelectField
        },
        props: {
            hideNavigation: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menuExpanded: false
            };
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-header-new {
        display: flex;
        position: fixed;
        height: $header-height;
        width: 100%;
        padding: theme-spacing( 3 ) theme-spacing( 4 );
        background: linear-gradient( 180deg, theme-color-hex( 'new-quaternary' ) 0%, rgba( theme-color-hex( 'new-quaternary' ), 0 ) 123.33% );
        background-color: rgba( theme-color-hex( 'new-quaternary' ), 1 );
        transition: all 0.5s ease;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        z-index: $z-index-header;

        a {
            color: theme-color( 'white' );
            font-size: 14px;
        }

        .gst-svg-icon {
            fill: theme-color( 'white' );
        }

        .gst-header__navigation {
            gap: theme-spacing( 6 );

            ::v-deep a {
                color: theme-color( 'white' );
            }
        }

        @include desktop-only {
            padding: 16px 24px;
        }
    }
</style>
