import normalizePackageHighlight from './packageHighlight';

export default function normalize( data ) {
    return {
        id: data.id,
        name: data.name,
        code: data.code,
        type: data.type,
        imageUrl: data.imageUrl,
        highlights: Array.isArray( data.highlights ) ? data.highlights.map( normalizePackageHighlight ) : [ ]
    };
}