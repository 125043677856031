import appendCurrencySymbol from '@tenant/app/filters/appendCurrencySymbol';

export default function ( { name, quantity, included, currency  }, options = { showCurrencySymbol: true } ) {
    let ret = name;
    const { showCurrencySymbol } = options;

    if ( quantity ) {
        ret = `${name} ${quantity}`;
    }

    if ( !included && showCurrencySymbol ) {
        ret = appendCurrencySymbol( name, currency );
    }

    return ret;
}
