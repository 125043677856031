export default {
    TYPES: {
        DIRECT:     'direct',
        BOTH:       'both',
        PROVIDER:   'provider'
    },
    USER_TYPES: {
        LOGGED_IN:   'loggedIn',
        LOGGED_OUT:  'loggedOut'
    },
};
