export default {
    home: 'https://www.ticketmaster.com/',
    search: 'https://www.ticketmaster.com/search',
    purchasePolicy: 'https://help.ticketmaster.com/hc/en-us/articles/10465798887953-Purchase-Policy',
    privacyPolicy: 'https://privacy.ticketmaster.com/privacy-policy',
    termsOfUse: 'https://help.ticketmaster.com/hc/en-us/articles/10468830739345-Terms-of-Use',
    ticketMasterLogin: 'https://identity.ticketmaster.com/',

    COUNTRIES: {
        US: {
            defaultLanguage: 'en',
            privacyPolicy: {
                en: 'https://privacy.ticketmaster.com/privacy-policy',
                es: 'https://privacy.ticketmaster.com/es/privacy-policy',
                fr: 'https://privacy.ticketmaster.com/fr/privacy-policy',
            }
        },
        AU: {
            defaultLanguage: 'en',
            termsOfUse: {
                en: 'https://www.ticketmaster.com.au/h/terms.html',
            },
            purchasePolicy: {
                en: 'https://www.ticketmaster.com.au/h/purchase.html',
            },
            privacyPolicy: {
                en: 'https://privacy.ticketmaster.com.au/privacy-policy'
            },
            travelExperiencePolicy: {
                en: 'https://help.ticketmaster.com.au/hc/en-au/articles/14639177610257-Travel-Experiences-Terms-and-Conditions-)',
            }
        },
        NZ: {
            defaultLanguage: 'en',
            termsOfUse: {
                en: 'https://www.ticketmaster.co.nz/h/terms.html',
            },
            purchasePolicy: {
                en: 'https://www.ticketmaster.co.nz/h/purchase.html',
            },
            travelExperiencePolicy: {
                en: 'https://help.ticketmaster.co.nz/hc/en-nz/articles/20029554781457',
            }
        },
        CA: {
            defaultLanguage: 'en',
            termsOfUse: {
                en: 'https://ticketmaster-ca.zendesk.com/hc/en-us/articles/10468826553361-Terms-of-Use',
                fr: 'https://ticketmaster-ca.zendesk.com/hc/fr-ca/articles/10468826553361-Conditions-d-utilisation-du-site-Ticketmaster-Canada',
            },
            purchasePolicy: {
                en: 'https://ticketmaster-ca.zendesk.com/hc/en-us/articles/10468488095505-Purchase-Policy',
                fr: 'https://ticketmaster-ca.zendesk.com/hc/fr-ca/articles/10468488095505-Politique-d-achat',
            },
            privacyPolicy: {
                en: 'https://privacy.ticketmaster.ca/en/privacy-policy',
                es: 'https://privacy.ticketmaster.ca/es/privacy-policy',
                fr: 'https://privacy.ticketmaster.ca/fr/privacy-policy',
            }
        },
        MX: {
            defaultLanguage: 'en',
            termsOfUse: {
                en: 'https://help.ticketmaster.com.mx/hc/en-us/articles/12682192759825-Terms-of-use',
                es: 'https://help.ticketmaster.com.mx/hc/es-mx/articles/12682192759825-Condiciones-de-Uso',
            },
            purchasePolicy: {
                en: 'https://help.ticketmaster.com.mx/hc/en-us/articles/6120032009745-Purchase-Policy',
                es: 'https://help.ticketmaster.com.mx/hc/es-mx/articles/6120032009745-Pol%C3%ADtica-de-compra',
            },
            privacyPolicy: {
                en: 'https://privacy.ticketmaster.com.mx/en/privacy-policy',
                es: 'https://privacy.ticketmaster.com.mx/es/privacy-policy',
                fr: 'https://privacy.ticketmaster.com.mx/fr/privacy-policy',
            }
        }
    }
};