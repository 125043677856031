<template>
    <BaseIcon symbol-id="icons--close" class="gst-icon-close" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconClose',
        components: {
            BaseIcon
        }
    };
</script>
