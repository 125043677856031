<template>
    <BaseIcon class="gst-icon-chevron-right" symbol-id="icons--dropdown_arrow" />
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'IconChevronRight',
        components: {
            BaseIcon
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";

    .gst-icon-chevron-right {
        transform: rotate( -90deg );
    }

    .gst-icon-chevron-right .gst-svg-icon {
        fill: theme-color( 'tertiary' );
    }
</style>