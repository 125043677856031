<template>
    <!-- OneTrust Cookies Settings button start -->
    <button
        id="ot-sdk-btn"
        class="ot-sdk-show-settings"
        :class="{
            'gst-floating-manage-cookies-button': floating
        }">
        {{ $t('manageCookiesButton') }}
    </button>
    <!-- OneTrust Cookies Settings button end -->
</template>

<script>
    export default {
        name: 'ManageCookiesButton',
        i18nOptions:{
            namespaces: '_common',
            keyPrefix: 'buttons'
        },
        props: {
            floating: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-floating-manage-cookies-button#ot-sdk-btn.ot-sdk-show-settings {
        position: fixed;
        left: 16px;
        bottom: 16px;
        line-height: 19px;
        padding: 12px;
        background-color: theme-color( 'new-secondary' );
        border-radius: 4px;
        z-index: $z-index-dialog;

        @include mobile-only {
            left: 8px;
            bottom: 8px;
        }
    }

    .gst-floating-manage-cookies-button#ot-sdk-btn.ot-sdk-show-settings:hover {
        background-color: #1BD4CA;
    }
</style>
