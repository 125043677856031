import environmentConstants from '@core/utils/constants/environment';
import prismicConstants from '@tenants/ticketmaster/app/utils/constants/prismic';
import {
    getType as environmentUtilsGetType
} from '@tenant/app/utils/environmentUtils';

/**
 * @returns string
 */
export function getApiUrl( ) {
    const environmentType = environmentUtilsGetType( );
    const { API_URLS } = prismicConstants;

    switch ( environmentType ) {
    case environmentConstants.TYPES.TEST:
        return API_URLS.TEST;
    case environmentConstants.TYPES.UAT:
        return API_URLS.UAT;
    case environmentConstants.TYPES.PRODUCTION:
        return API_URLS.PRODUCTION;
    default:
        return API_URLS.LOCALHOST;
    }
}

/**
 *
 * @returns string
 */
export function getPreviewScriptUrl( ) {
    const environmentType = environmentUtilsGetType( );
    const { PREVIEW_SCRIPT_URLS } = prismicConstants;

    switch ( environmentType ) {
    case environmentConstants.TYPES.TEST:
        return PREVIEW_SCRIPT_URLS.TEST;
    case environmentConstants.TYPES.UAT:
        return PREVIEW_SCRIPT_URLS.UAT;
    case environmentConstants.TYPES.PRODUCTION:
        return PREVIEW_SCRIPT_URLS.PRODUCTION;
    default:
        return PREVIEW_SCRIPT_URLS.LOCALHOST;
    }
}

export function addCounterOnSlices( content ) {
    const slicesAppearance = content.data.body
        .map( item => item.slice_type )
        .reduce( ( acc, item ) => {
            acc[ item ] = 0;
            return acc;
        }, {} );
    return content.data.body
        .map( item => {
            slicesAppearance[ item.slice_type ]++;
            const ret = {
                ...item,
                index: slicesAppearance[ item.slice_type ]
            };
            return ret;
        } );
}

export function isRichTextNotEmpty ( field ) {
    return !!field[ 0 ]?.text;
}
