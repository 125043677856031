import apiConstants from '@core/utils/constants/api';
import { createApiService, fetchAPI } from '@core/api/_utils';

let api = {
    tenant: {
        getConfig: (  ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/tenant/config` );
        },
        getSSOLoginUrl: ( { urlSuccess, urlFailure, locale } ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/tenant/sso`, {
                urlOk: urlSuccess,
                urlFail: urlFailure,
                locale
            } );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
