import {
    getSetting as getSettingStorageUtils,
    saveSetting as saveSettingStorageUtils
} from '@core/utils/storageUtils';

const LOCAL_STORAGE_KEYS = {
    USER_TOKEN: 'gstUserToken',
    USER_RULE_SET: 'userRuleSet'
};

const isValidUUID = ( uuid ) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    return regex.test( uuid );
};

const service = {
    userToken: {
        set( token ) {
            if ( isValidUUID( token ) ) {
                const expireTime = 24 * 3600 * 1000; // 24 hours
                saveSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_TOKEN, token, expireTime );
            }
        },
        get( ) {
            const token = getSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_TOKEN );
            if ( token?.value && isValidUUID( token.value ) ) {
                return token.value;
            } else {
                localStorage.removeItem( LOCAL_STORAGE_KEYS.USER_TOKEN );
                return null;
            }
        },
        refresh( ) {
            const value = service.userToken.get( );
            if ( value ) {
                service.userToken.set( value );
            }
        }
    },
    userRuleSet: {
        set( rule ) {
            const expireTime = 24 * 3600 * 1000; // 24 hours
            saveSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_RULE_SET, rule, expireTime );
        },
        get( ) {
            const rule = getSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_RULE_SET );
            if ( rule?.value ) {
                return rule.value;
            } else {
                localStorage.removeItem( LOCAL_STORAGE_KEYS.USER_RULE_SET );
            }
        }
    }
};

export default service;
