<template>
    <div
        v-if="items.length > 1"
        class="gst-lang-select"
        :class="{
            'gst-lang-select--short-form': hasShortForm,
            'gst-lang-select--secondary': isSecondaryType
        }">
        <v-menu
            v-model="menuOpened"
            offset-y
            :auto="showOnTop"
            :top="showOnTop"
            content-class="gst-city-dropdown__menu gst-lang-dropdown-menu rounded-sm"
            :close-on-content-click="true">
            <template v-slot:activator="{ on }">
                <v-btn
                    ref="button"
                    text
                    :ripple="false"
                    :x-small="$vuetify.breakpoint.mdAndUp"
                    :class="['gst-lang-select__button', menuOpened && 'gst-lang-select__button--active']"
                    v-on="on">
                    <BaseIcon
                        class="u-mouse-pointer"
                        symbol-id="icons--message" />
                    <span class="gst-lang-select__label">{{ languageLabel }}</span>
                </v-btn>
            </template>
            <v-list class="gst-lang-select__list pa-2">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :class="['gst-lang-select__list-item', defaultSelected === item.id && 'v-list-item--active gst-lang-select__list-item--active']"
                    @click="onLanguageSelectDo( item.id )">
                    <v-list-item-title class="gst-lang-select__item-title">
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import i18next from 'i18next';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'LanguageSelectField',
        i18nOptions: { keyPrefix: 'common', namespaces: 'shared' },
        components: {
            BaseIcon
        },
        props: {
            isSecondaryType: {
                type: Boolean,
                default: false
            },
            showOnTop: {
                type: Boolean,
                default: false
            },
            hasShortForm: {
                type: Boolean,
                default: true
            }
        },
        data: () => ( {
            menuOpened: false
        } ),
        computed: {
            ...mapGetters( {
                availableLangs: 'languages/getAvailableLangs',
                defaultSelected: 'appState/getLocale'
            } ),
            languageLabel( ) {
                return this.defaultSelected.split( '-' )[0].toUpperCase( ).trim( );
            },
            supportedLocales( ) {
                return Object.keys( i18next.services.resourceStore.data )
                    .map( item => {
                        const translationKey = `shared:common.languages.${item}.name`;
                        const name = i18next.exists( translationKey ) ? this.$t( translationKey ) : item.toUpperCase();

                        return {
                            id: item,
                            name
                        };
                    } );
            },
            items() {
                if ( this.availableLangs.length ) {
                    return this.supportedLocales.reduce( ( acc, item ) => {
                        if ( this.availableLangs.includes( item.id.toLowerCase( ) ) ) {
                            acc.push( item );
                        }
                        return acc;
                    }, [] );
                }
                return this.supportedLocales;
            }
        },
        watch: {
            items: {
                handler: function ( value ) {
                    // change the language of the app if the current language is not available in options
                    const exists = value.find( item => item.id === this.defaultSelected );
                    if ( !exists ) {
                        this.onLanguageSelectDo( value[0].id );
                    }
                },
                deep: true
            }  
        },
        methods: {
            onLanguageSelectDo( lang ) {
                if ( lang !== this.defaultSelected ) {
                    const route = this.$router.resolve( { 
                        ...this.$route, 
                        query: {
                            ...this.$route.query,
                            lang
                        }
                    } );
                    window.location.replace( route.href );
                }
            }
        }
    };
</script>
<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-lang-dropdown-menu {
    max-height: 350px;
    z-index: $z-index-dialog - 1 !important;
}

.gst-lang-select {
    .v-btn.gst-lang-select__button {
        width: auto;
        padding: theme-spacing( 0 ) !important;
        background: transparent !important;
        color: theme-color( 'white' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
        min-width: unset !important;

        svg {
            margin-right: theme-spacing( 1 );
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .v-btn.gst-lang-select__button--active {
        text-decoration: underline;
    }

    .v-btn::before {
        background-color: transparent;
    }

    .v-btn::after {
        background-color: transparent;
    }

    &.gst-lang-select--secondary {
        .v-btn.gst-lang-select__button {
            color: theme-color( 'new-quaternary' );

            svg .gst-svg-icon {
                fill: theme-color( 'new-quaternary' );
            }
        }
    }
}

.gst-lang-select__list {
    padding-right: theme-spacing( 0 );
    min-width: 170px;
}

.v-list-item.gst-lang-select__list-item {
    padding-right: theme-spacing( 0 );
    min-height: 42px !important;

    &:hover {
        background-color: rgba( 246, 246, 246, 1 );
    }

    .gst-lang-select__item-title {
        color: theme-color( 'new-quaternary' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'medium' );

        &:hover {
            color: theme-color( 'new-primary' ) !important;
        }
    }
}

.v-list-item.gst-lang-select__list-item--active {
    background-color: rgba( 246, 246, 246, 1 );

    .gst-lang-select__item-title {
        &:hover {
            color: theme-color( 'new-primary' ) !important;
        }
    }
}

.v-list-item.gst-lang-select__list-item--active::before {
    background-color: transparent !important;
}

@include mobile-xss-only {
    .gst-lang-select--short-form .gst-lang-select__label {
        display: none;
    }
}
</style>
